import { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom"
import { Images } from "../../img/Images";

import styles from "./AppDone.module.scss";

export default function AppDone() {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);

	return (
		<>
			<Container className="d-flex flex-grow-1 flex-column justify-content-center">
				<div className={`${styles.imageContainer} d-block m-auto text-center mb-3`}>
					<img src={Images.fin} alt="Ziel-Bild" className="img-fluid clickable" onClick={handleShow} />
					<span className="fs-6 d-block">Zum Bild-größer-machen aufs Bild klicken</span>
				</div>
				<div className="text-center my-3">
					<Link className="btn btn-primary btn-lg" to="/feedback">Feedback abgeben</Link>
				</div>
			</Container>
			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src={Images.fin} alt="Ziel-Bild" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}