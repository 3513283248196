import styles from "./FeedbackSurvey.module.scss";

export default function FeedbackSurvey() {
	return (
		<>
			<iframe
				className={styles.feedback}
				title="Umfrage"
				src="https://docs.google.com/forms/d/e/1FAIpQLSco9NBXw0otrVdmF2_kKe8NS_ME4Mf4KJ0KTVHcmmra_w6CCw/viewform?embedded=true"
				frameBorder="0"
				marginHeight={0}
				marginWidth={0}>
					Wird geladen...
			</iframe>
		</>
	);
}