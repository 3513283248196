import { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import styles from "./About.module.scss";

export default function About() {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);

	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-5">Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen</h1>
					<img className={`img-fluid d-block m-auto mb-5 ${styles.portrait}`} src="/portrait_about.jpg" alt="Portrait" />
					<p>
						Herzlich Willkommen.
						<br />
						Vielen Dank, dass Sie sich für "Psychodynamik-Navi" interessieren.
						<br />
						Gerne stelle ich Ihnen etwas über den Hintergrund meiner Person im Kontext dieses neuen Programms vor.
					</p>
					<p>
						Ich befasse mich seit ca. 2006 mit didaktisch-konzeptuellen Fragestellungen in der Psychodynamischen Psychotherapie sowie dem Gutachterverfahren (Schwerpunkt Psychodynamik in der tiefenpsychologisch fundierten Psychotherapie).
					</p>
					<p>
						Nach der Seminar-Praxis für Psychologische und Ärztliche Psychotherapeuten im Rahmen meines eigenen Fortbildungs-Instituts Psy-Dak (<a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">www.psy-dak.de</a>) und bundesweiten Instituts-Kooperationen, kam es 2013 (1. Aufl.) und 2018 (2. Aufl.) zur Veröffentlichung des Handbuches Psychotherapie-Antrag im Schattauer-Verlag.
					</p>
					<p>
						Infos <a href="https://psy-dak.de/das-buch-zum-seminar/neuauflage-handbuch-2018/" target="_blank" rel="noreferrer">hier</a> und <a href="https://psy-dak.de/das-buch-zum-seminar/" target="_blank" rel="noreferrer">hier</a>
					</p>
					<p>
						In diesem Buch, welches heute u.a. in der Psychotherapie-Ausbildung eingesetzt wird- wurden meine Seminar-Konzepte zur Psychodynamischen Psychotherapie veröffentlicht und regelmäßig erweitert.
					</p>
					<p>
						Meine Leidenschaft ist die Weiter-Entwicklung, Umsetzung und Erforschung neuer didaktischer Formen in der Vermittlung der Psychodynamischen Psychotherapie, auch unter Berücksichtigung E-Learning-basierter bzw. elektronischer Methoden.
					</p>
					<p>
						Das vorliegende Programm ist mein aktuelles Projekt, in dem meine Konzepte mit einer neuartigen digitalen Strukturierungshilfe für die Berichtserstellung für Sie verbunden werden.
					</p>
					<p>
						Im Fokus dieses Programms steht dabei nicht die Nutzung von Textbausteinen oder Ähnlichem. Im Mittelpunkt steht die Strukturierung Ihres gedanklichen-schriftlichen Arbeit im Sinne einer Portionierung.
					</p>
					<p>
						Der Lern- und Fortbildungs-Charakter des Programms soll künftig durch neue Programm-Erweiterungen unterstützt werden
					</p>
					<p>
						Durch umfangreiche Zusatz-Texte mit Buch-Charakter – im Rahmen des Upgrades - werden Sie eingeladen, sich mit den Inhalten der Psychodynamischen Psychotherapie über die Lektüre und eigene Wissensüberprüfung vertiefter zu befassen.
					</p>
					<p>
						Lesen Sie <Link to="/upgrades">hier</Link> zu Informationen zu Upgrade-Möglichkeiten
					</p>
					<p>
						Die folgende Abbildung gibt Ihnen eine gute Übersicht über vier verschiedene Ebenen meiner Arbeit im Rahmen von Psy-Dak (<a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">www.psy-dak.de</a>):
					</p>
					<div className="d-block m-auto w-50 text-center mb-3">
						<img src="/info_psy_dak.jpg" alt="Info Psy Dak" className="img-fluid clickable" onClick={handleShow} />
						<span className="fs-6">Zum Bild-größer-machen aufs Bild klicken</span>
					</div>
					{/* <img className="img-fluid d-block m-auto mb-4" src="/info_psy_dak.jpg" alt="Info Psy Dak" /> */}
					<p>
						Außerhalb dieser Tätigkeit bin ich als Psychologie-Professor an der Fachhochschule des Mittelstands in Köln tätig. Infos <a href="https://bit.ly/3gP0TIN" target="_blank" rel="noreferrer">hier</a>.
					</p>
					<p>
						Wenn Sie mehr über mich erfahren möchten, finden Sie auf meiner <a href="https://psy-dak.de/ueber-mich/" target="_blank" rel="noreferrer">Fortbildungsseite (Über mich)</a> weitere Informationen zu meiner Person und Hintergründen meiner bisherigen Arbeit.
					</p>
					<p>
						Bei vertieftem Interesse stehen Ihnen folgende zusammenfassende Einblicke in meine Arbeiten zur Verfügung:
					</p>
					<ul>
						<li>Fortbildungsangebot Psychodynamik, Theorien zur Psychoanalyse uvm. <a href="http://www.psy-dak.de/" target="_blank" rel="noreferrer">hier</a></li>
						<li>Newsletter-Eintrag Seminare <a href="https://psy-dak.de/newsletter/" target="_blank" rel="noreferrer">hier</a> und E-Learning <a href="https://frag-freud.de/anmeldung-newsletter/" target="_blank" rel="noreferrer">hier</a></li>
						<li>Lebenslauf <a href="https://psy-dak.de/ueber-mich/lebenslauf/" target="_blank" rel="noreferrer">hier</a></li>
						<li>Frag-Freud (E-Learning und Psychoanalyse) <a href="https://frag-freud.de/" target="_blank" rel="noreferrer">hier</a></li>
						<li>Weitere Arbeits- und Forschungsschwerpunkte sind u.a. die Psychodynamische Konfliktdiagnostik (Infos <a href="https://psy-dak.de/wp-content/uploads/2020/02/PTJ-3_2018-Jungclaussen-Hauten-KMK-Modell.pdf" target="_blank" rel="noreferrer">hier</a> und <a href="https://psy-dak.de/artikel/psychodynamische-konfliktdiagnostik-kmk-modell/" target="_blank" rel="noreferrer">hier</a>) sowie die Reform der Psychotherapieausbildung</li>
						<li>Gast-Herausgeberschaft zusammen mit Dr. Lars Hauten der Fachzeitschrift "Psychodynamische Psychotherapie" der DFT, zum Schwerpunkt-Thema "Ausbildung". (2/2021) <a href="https://www.klett-cotta.de/ausgabe/PdP_-_Psychodynamische_Psychotherapie_Heft_02_Juni_2021/144144" target="_blank" rel="noreferrer">hier</a></li>
					</ul>
				</div>
			</Container>

			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src="/info_psy_dak.jpg" alt="Info Psy Dak" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}