import { Container, Card  } from "react-bootstrap";

export default function Privacy() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Datenschutzhinweise für die Nutzung von Psychodynamik-Navi</h1>
					<p>
						Das Wichtigste zum Datenschutz in der Schnell-Übersicht: 
					</p>
					<Card className="mb-5">
						<Card.Body>
							<ol>
								<li>Cookies oder Analysetools werden vom Programm nicht genutzt</li>
								<li>Das Schreib-Programm Psychodynamik-Navi wurde so konstruiert, dass zu keinem Zeitpunkt Ihre Text-Eintragungen (Patienten-Daten) an einen Server gesendet oder auf einem Server gespeichert werden. Das Programm ist absichtlich so konstruiert worden, dass alle Ihre Texteintragungen ausschließlich in Ihrem Browser lokal auf Ihrem Rechner gespeichert werden. Ihre Patienten-Daten, die Sie in die Felder eintragen, verlassen also zu keinem Zeitpunkt (!) Ihren Computer. Ihre patientenbezogenen Daten bleiben also lokal im Browser Ihres Rechners.</li>
								<li>Nach Abschluss der Berichtserstellung (<q>Bericht generieren</q>) oder bei Klicken auf <q>Alle Daten löschen</q> werden selbst die lokal in Ihrem Browser gespeicherten Eintragungen / Daten vom Programm restlos gelöscht.</li>
								<li>Darüber hinaus kann angemerkt werden, dass Ihr Berichtsentwurf mit <u>keiner</u> Chiffre versehen wird. Die Chiffre im Bericht an den Gutachter tragen Sie selbst am Ende der Prozedur in Ihre eigene Datei ein, wie sonst auch.</li>
								<li>Abschließend wird angeführt, dass zur Nutzung des Schreibprogramms nur das Eintragen eines Lizenzschlüssels (Token) erforderlich ist, den Sie vorher im Shop von <a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">www.psy-dak.de</a> erworben haben. Weder das Eintragen Ihrer E-Mail-Adresse noch eines möglichen Namens/Benutzernamen etc. sind erforderlich, um das Schreibprogramm Psychodynamik-Navi zu nutzen. Es werden nur diejenigen Daten für kurze Zeit erfasst, die für die Überprüfung der Gültigkeit des Lizenzschlüssels erforderlich sind.</li>
								<li>Mit dem Kauf eines Lizenzschlüssels (Token) unter <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a> und dessen Einlösung unter <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> kann die elektronische Schreibhilfe für Psychotherapieanträge <q>Psychodynamik-Navi</q> für den bezahlten Zeitraum genutzt werden. Alle hierfür einschlägigen Rechtsvorgaben (u.a. zum Medizinprodukterecht oder Datenschutz) werden eingehalten. Bei rechtlichen Rückfragen zum Schreibprogramm stehen Ihnen gerne auch die Anwälte der Kanzlei SKW Schwarz zur Verfügung. Ihr Ansprechpartner ist: Dr. Matthias Orthwein, Rechtsanwalt und Partner (<a href="mailto:m.orthwein@skwschwarz.de">m.orthwein@skwschwarz.de</a>)</li>
							</ol>
						</Card.Body>
					</Card>
					<p className="fw-bold">
						Ausführliche Darstellung der Datenschutzerklärung:
					</p>
					<p>
						Der Schutz auf Sie bezogener Informationen, wie etwa Ihr Name sowie Ihre E-Mail- oder IP-Adresse (so genannte <q className="fw-bold">personenbezogene Daten</q>), ist uns ein wichtiges Anliegen. Deshalb betreiben wir diese Webseite und von uns darauf angebotene Dienste gemäß den gültigen Datenschutzgesetzen, insbesondere der EU-Datenschutzgrundverordnung (<q className="fw-bold">DSGVO</q>) und dem Bundesdatenschutzgesetz (<q className="fw-bold">BDSG</q>).
					</p>
					<p>
						Sie finden im Folgenden eine Erläuterung, wie wir mit Ihren personenbezogenen Daten in diesem Zusammenhang umgehen.
					</p>
					<ol>
						<li>
							<p className="fw-bold">Wer ist Verantwortlicher für Psychodynamik-Navi (oder Berichts-Navi)?</p>
							<p>
								Verantwortlich für diese Webseite und Ihr Ansprechpartner zum Datenschutz ist:<br/>
								Psy-Dak- Fortbildungs-Institut für Psychodynamische Didaktik,<br/>
								Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen,<br/>
								Erikastraße 18, 40627 Düsseldorf<br/>
								E-Mail: <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>
							</p>
						</li>
						<li>
							<p className="fw-bold">Was machen wir mit Ihren eigenen personenbezogenen Daten?</p>
							<ol type="a">
								<li>
									<p className="fw-bold">Wenn Sie die Webseite <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> nutzen</p>
									<p>
										Die Bereitstellung dieser Webseite erfordert die Verarbeitung von personenbezogenen Daten, insbesondere Ihrer IP-Adresse. Diese Verarbeitung ist für den Abruf der auf dieser Webseite dargestellten Inhalte (einschließlich ihrer Funktionen sowie der Optimierung) sowie aufgrund von IT-Sicherheitsmaßnahmen und zum Schutz vor betrügerischem Missbrauch erforderlich. Unsere Website wird von IONOS (1&amp;1) gehostet und der Dienst wird selbst betrieben. Der Standort des Servers für unsere Webseite liegt in Deutschland.
									</p>
									<p>
										Sie haben zudem die Möglichkeit, mit uns in Verbindung zu treten. Wir verarbeiten hierfür diejenigen personenbezogenen Daten, die Sie uns jeweils zur Verfügung stellen (z.B. per E-Mail). Cookies werden von der Webseite <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> nicht genutzt (s. auch unten). 
									</p>
									<ol type="1">
										<li>
											<p className="fw-bold">Rechtsgrundlage</p>
											<p>Die Verarbeitung Ihrer personenbezogenen Daten zur Bereitstellung dieser Webseite und zur Kommunikation mit Ihnen erfolgt aufgrund unseres überwiegenden berechtigten Interesses. Für die Bereitstellung dieser Webseite ist es technisch notwendig, dass wir bestimmte personenbezogene Daten verarbeiten (z. B. die IP-Adresse). Für Ihre Kommunikation mit uns ist es notwendig, dass wir mit Ihren jeweiligen personenbezogenen Daten umgehen.</p>
										</li>
										<li>
											<p className="fw-bold">Interessenabwägung</p>
											<p>Im Rahmen der erforderlichen Interessenabwägungen haben wir Ihr Vertraulichkeitsinteresse und unsere Interessen an der Bereitstellung dieser Webseite und dem Betrugsschutz jeweils miteinander abgewogen. Ihr Vertraulichkeitsinteresse tritt dabei jeweils zurück. Wir könnten Ihnen gegenüber andernfalls diese Webseite nicht bereitstellen oder uns gegen betrügerische Mehrfachverwendungen von Tokens (= Lizenzschlüssel) schützen.</p>
										</li>
										<li>
											<p className="fw-bold">Empfängerkategorien</p>
											<p>Wir setzen Dienstleister zur Bereitstellung unserer Webseite ein (z.B. für Hosting des Servers). Wir übermitteln hierfür personenbezogene Daten an diese Dienstleister. Diese Dienstleister sind von uns vertraglich verpflichtet, die gleiche Sorgfalt bei der Verarbeitung von personenbezogenen Daten walten zu lassen wie wir selbst.</p>
										</li>
									</ol>
								</li>
								<li>
									<p className="fw-bold">Wenn Sie unsere digitale Schreibhilfe für den Antragsbericht nutzen</p>
									<p>
										Sie können auf der Webseite <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> Ihren gekauften Token (Lizenzschlüssel) einlösen (den Sie unter <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a> gekauft haben) und die Schreibhilfe anschließend nutzen (unter <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a>). Für den Start der Schreibhilfe (durch Eingabe Ihres Tokens/ Lizenzschlüssels) ist weder das Eintragen noch das Speichern von personenbezogenen Daten, wie E-Mail, Name etc. erforderlich. Wir erheben nur für den Zeitraum von maximal 7 Tagen, diejenigen personenbezogenen Daten, die für die Überprüfung der Gültigkeit Ihres Tokens und somit für den Start des Programms erforderlich sind (IP-Adresse). Sie hierzu auch Punkt 3.
									</p>
									<ol type="1">
										<li>
											<p className="fw-bold">Rechtsgrundlagen</p>
											<p>Wir verarbeiten Ihre personenbezogenen Daten zur Anbahnung, Durchführung und Abwicklung des entsprechenden Nutzungsvertrages.</p>
										</li>
										<li>
											<p className="fw-bold">Empfängerkategorien</p>
											<p>Wir setzen Dienstleister im Rahmen unseres Services <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> ein ([IT Dienstleister sowie Hosting-Dienstleister]). Wir übermitteln hierfür personenbezogene Daten an diesen Dienstleister. Dieser Dienstleister ist von uns vertraglich verpflichtet, die gleiche Sorgfalt bei der Verarbeitung von personenbezogenen Daten walten zu lassen wie wir selbst. Dieser Dienstleister verarbeitet personenbezogene Daten ausschließlich innerhalb der EU/des EWR.</p>
										</li>
									</ol>
								</li>
								<li>
									<p className="fw-bold">Werden von der Seite Cookies oder Analysetools genutzt</p>
									<Card className="mb-3">
										<Card.Body>
											Nein, für die Bereitstellung unserer Webseite <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> werden weder Cookies noch Analysetools (z.B. Google Analytics) benutzt!
										</Card.Body>
									</Card>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Wie lange speichern wir Ihre eigenen personenbezogenen Daten?</p>
							<p>
								Wir löschen Ihre personenbezogenen Daten (=IP-Adresse), wenn der jeweilige Zweck der Speicherung entfällt (d.h. wenn die Gültigkeit Ihres Lizenzschlüssel abgelaufen ist, spätestens nach 7 Tagen) und keine gesetzliche Vorschrift eine Aufbewahrung erfordert (z. B. eine Aufbewahrungspflicht nach der Abgabenordnung und/oder dem Handelsgesetzbuch). Ist eine Löschung im Einzelfall nicht möglich, wird die Verarbeitung eingeschränkt.
							</p>
							<Card className="mb-3">
								<Card.Body>
									Ferner wird angeführt, dass zur Nutzung des Schreibprogramms nur das Eintragen eines Lizenzschüssels (Token) erforderlich ist, den Sie vorher im Shop von <a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">www.psy-dak.de</a> erworben haben. Das Eintragen von Ihrer E-Mail-Adresse oder eines möglichen Benutzernamen etc. sind nicht erforderlich, um das Schreibprogramm Psychodynamik-Navi zu nutzen. 
								</Card.Body>
							</Card>
						</li>
						<li>
							<p className="fw-bold">Werden patientenbezogene Daten gespeichert oder an einen Server gesendet?</p>
							<Card className="mb-3">
								<Card.Body>
									<p>Nein.</p>
									<p>Das Schreib-Programm Psychodynamik-Navi wurde so konstruiert, dass zu keinem Zeitpunkt Ihre Text-Eintragungen (Patienten-Daten) an einen Server gesendet oder auf einem Server gespeichert werden. Das Programm ist absichtlich so konstruiert worden, dass alle Ihre Texteintragungen ausschließlich in Ihrem Browser lokal gespeichert werden. Ihre Patienten-Daten, die Sie in die Felder eintragen, verlassen also zu keinem Zeitpunkt (!) Ihren Computer. Ihre patientenbezogenen Daten bleiben also lokal im Browser Ihres Rechners.</p>
									<p>Nach Beendigung Ihrer Nutzung klicken Sie auf <q>alle Daten löschen</q>, dann werden auch noch die lokal in Ihrem Browser gespeicherten Daten vollständig gelöscht. Sollten Sie vergessen auf <q>alle Daten löschen</q> zu klicken, werden Ihre Daten auch dann gelöscht, wenn am Ende der Bericht generiert wird (Sie also auf <q>Bericht generieren</q> klicken) Mit anderen Worten: Nach Abschluss der Berichtserstellung werden selbst die lokal in Ihrem Browser gespeicherten Eintragungen / Daten vom Programm restlos gelöscht. </p>
									<p>Auch nach Beendigung des Programms bleiben also keine Texteintragungen zurück. </p>
									<p>Zwischenspeicher-Funktion: Auch wenn Sie die Funktion benutzen Ihre Texteintragungen mit Hilfe der .json-Datei zwischenzuspeichern (Export) und später wieder zu ins Programm reinzuladen (Import), um daran weiterzuarbeiten, auch dann verbleiben die Daten in dieser Datei auf Ihrem Rechner lokal.</p>
									<p>Bericht vorzeitig beenden-Funktion: Sie haben die Möglichkeit vor Beendigung des Leitfadens Ihre Eintragungen bereits als Bericht generieren zu lassen. Dafür klicken Sie auf <q>Bericht vorzeitig abschließen</q>. Auch dann werden alle Ihre lokal im Browser gespeicherten Daten vollständig auf Ihrem Rechner gelöscht. </p>
									<p>Darüber hinaus kann angemerkt werden, dass Ihr Berichtsentwurf mit <u>keiner</u> Chiffre versehen wird. Die Chiffre im Bericht an den Gutachter tragen Sie selbst am Ende der Prozedur in Ihre eigene Datei ein, wie sonst auch. Die Anwendung verwendet keine Cookies. </p>
								</Card.Body>
							</Card>
						</li>
						<li>
							<p className="fw-bold">Welche Betroffenenrechte haben Sie?</p>
							<p>Bitten wenden Sie sich für die Wahrnehmung Ihrer Rechte an die o.g. Kontaktdaten.</p>
							<ol type="a">
								<li>Sie haben das Recht, jederzeit Auskunft über alle personenbezogenen Daten zu verlangen, die wir von Ihnen verarbeiten.</li>
								<li>Sollten Ihre personenbezogenen Daten unrichtig oder unvollständig sein, haben Sie ein Recht auf Berichtigung und Ergänzung.</li>
								<li>Sie können jederzeit die Löschung Ihrer personenbezogenen Daten verlangen, sofern wir nicht rechtlich zur weiteren Verarbeitung Ihrer Daten verpflichtet oder berechtigt sind.</li>
								<li>Bei Vorliegen der gesetzlichen Voraussetzungen können Sie eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten verlangen.</li>
								<li>Sie haben das Recht gegen die Verarbeitung Widerspruch zu erheben, soweit die Datenverarbeitung zum Zwecke der Direktwerbung oder des Profilings erfolgt. Erfolgt die Verarbeitung auf Grund einer Interessenabwägung, so können Sie der Verarbeitung unter Angabe von Gründen, die sich aus Ihrer besonderen Situation ergeben, widersprechen.</li>
								<li>Erfolgt die Datenverarbeitung auf Grundlage Ihrer Einwilligung oder im Rahmen eines Vertrages, so haben Sie ein Recht auf Übertragung der von Ihnen bereitgestellten Daten, sofern dadurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden.</li>
								<li>Sofern wir Ihre Daten auf Grundlage einer Einwilligungserklärung verarbeiten, haben Sie jederzeit das Recht, diese Einwilligung mit Wirkung für die Zukunft zu widerrufen. Die vor einem Widerruf durchgeführte Verarbeitung bleibt von dem Widerruf unberührt.</li>
								<li>
									<p>Sie haben außerdem jederzeit das Recht, bei einer Aufsichtsbehörde für den Datenschutz Beschwerde einzulegen, wenn Sie der Auffassung sind, dass eine Datenverarbeitung unter Verstoß gegen geltendes Recht erfolgt ist.</p>
									<p>Für uns ist die folgende Datenschutzaufsichtsbehörde zuständig:</p>
									<p>
										Die Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen (LDI NRW), <br/>
										Kavalleriestr. 2-4, 40213 Düsseldorf, <br/>
										Telefon: 0211- 38424-0,  <br/>
										E-Mail: <a href="mailto:poststelle@ldi.nrw.de">Poststelle@ldi.nrw.de</a>
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Werden automatische Profile erstellt?</p>
							<p>Nein. Wir erstellen keine automatischen Profile.</p>
						</li>
						<li>
							<p className="fw-bold">Datenübermittlung in Drittstaaten</p>
							<p>Es findet keine Datenübertragung in Drittstaaten statt.</p>
						</li>
						<li>
							<p className="fw-bold">Datensicherheit</p>
							<p>Wir treffen geeignete technische und organisatorische Sicherheitsmaßnahmen, um die durch uns verarbeiteten personenbezogenen Daten gegen zufällige oder vorsätzliche Manipulation, Verlust, Zerstörung oder gegen den unberechtigten Zugriff zu schützen.</p>
						</li>
					</ol>
				</div>
			</Container>
		</>
	);
}