import { Container } from "react-bootstrap";

export default function AGB() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Nutzungsbedingungen / Allgemeine Geschäftsbedingungen (AGB)</h1>
					<p>Anmerkung: Neben den Nutzungsbedingungen (rechtlicher Art) wird auf die hilfreiche FAQ-Liste (praktischer Art) verwiesen. Diese finden Sie <a href="https://www.psychodynamik-navi.de/faq" target="_blank" rel="noreferrer">hier</a></p>
					<ol type="I">
						<li>
							<p className="fw-bold">Allgemeines</p>
							<p>
								Diese Nutzungsbedingungen gelten für die Nutzung der Plattform <a href="https://psychodynamik-navi.de/" target="_blank" rel="noreferrer">https://psychodynamik-navi.de/</a> (nachfolgend <q className="fw-bold">Plattform</q>) und den über diese Webseite angebotenen Dienst der elektronischen Schreibhilfe für Psychotherapieanträge (nachfolgend <q className="fw-bold">Schreibhilfe</q>). Die Plattform und die Schreibhilfe werden von dem Psy-Dak- Fortbildungs-Institut für Psychodynamische Didaktik, Prof. Dr. phil. Dipl.-Psych. Ingo Jungclaussen, Erikastraße 18, 40627 Düsseldorf (Email: <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>) (nachfolgend <q className="fw-bold">Anbieter</q>), angeboten.
							</p>
							<p>
								Soweit nicht anderweitig geregelt, gelten diese Nutzungsbedingungen für alle Versionen, Teile, Updates/Upgrades oder Modifizierungen der Schreibhilfe, die der Anbieter jeweils zum Zeitpunkt der Anwendung auf der Plattform zur Verfügung stellt.
							</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Nutzungsvoraussetzungen</p>
									<p>
										Die Nutzung der Schreibhilfe ist nur Unternehmern im Sinne des &sect; 14 BGB (also vor allem Unternehmern und selbständig Tätigen) bzw. deren Mitarbeitern oder Beauftragten erlaubt, wenn der Unternehmer diese entsprechend zur Nutzung der Schreibhilfe ermächtigt (nachfolgend zusammen <q className="fw-bold">Nutzer</q>). Verbrauchern im Sinne von &sect; 13 BGB kann die Schreibhilfe leider nicht zur Verfügung gestellt werden. Sie sind von der Nutzung ausgeschlossen.
									</p>
									<p>
										Im Sinne der Lesbarkeit und Verständlichkeit wird auf die weibliche und andere Sprachformen verzichtet. Die Verwendung der männlichen Sprachform stellt keine Diskriminierung dar. Der Nutzer, Nutzungsberechtigte oder Dritte etc. ist geschlechtsneutral anzusehen und gilt für männliche, weibliche und diverse Personen gleichermaßen.
									</p>
									<p>
										Die Nutzer müssen für die Nutzung der Schreibhilfe auf der Plattform Psychodynamik-Navi zuvor einen gültigen Lizenzschlüssel (im weiteren <q>Token</q> genannt) einlösen. Der Token kann vorab im Webshop auf <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a> käuflich erworben werden. Der Token erhält seine aktive Gültigkeitsdauer direkt ab Kauf im genannten Shop und kann direkt danach (ab Kauf) auf der Plattform <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> eingelöst werden. Ab dem Zeitpunkt des Kaufs kann die Schreibhilfe für den definierten Zeitraum (z.B. von zwei bzw. sieben Tagen), also je nach Art des gekauften Tokens, genutzt werden.
									</p>
									<p>
										Weitere Nutzungsvoraussetzungen sind neben einer stabilen Internetverbindung folgende technische Voraussetzungen: 
									</p>
									<p>
										Die Nutzung des Programms ist nur möglich mit der Nutzung folgender Internet-Browser:<br/>
										Google Chrome, Microsoft Edge, Mozilla Firefox, Safari und Opera in der jeweils aktuellsten Version.
									</p>
									<p>
										Die Nutzung des Programms ist nur möglich mit der Nutzung folgender Textverarbeitungsprogramme:<br/>
										Microsoft Word, Open Office, Libre Office sowie Apple Pages
									</p>
								</li>
								<li>
									<p className="fw-bold">Leistungsbeschreibung</p>
									<p>
										Die Plattform dient ausschließlich der Einlösung des auf <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a> erworbenen Tokens zur Nutzung der digitalen Schreibhilfe. Nach der Einlösung des Tokens und Freischaltung der Nutzung durch den Anbieter kann die Schreibhilfe als HTML Dokument auf dem Endgerät des Nutzers für den bezahlten Zeitraum genutzt werden. Die Nutzung der Schreibhilfe und Speicherung von Inhalten findet ausschließlich im Local Storage und insoweit auf dem Endgerät des Nutzers statt.
									</p>
									<p>
										Der Anbieter ist nicht verpflichtet, außer der Schreibhilfe weitere Leistungen oder Produkte zur Verfügung zu stellen. Es besteht insbesondere keine Pflicht zur Zurverfügungstellung von Quellcodes, Schnittstellen zu anderen Programmen, Speicherplatz oder nicht vereinbarten Supportleistungen. Eine technische Anleitung zur Nutzung der Schreibhilfe ist auf der Plattform unter <a href="https://psychodynamik-navi.de" target="_blank" rel="noreferrer">https://psychodynamik-navi.de</a> verfügbar (Startseite/Home, Bericht schreiben sowie unter FAQs) 
									</p>
									<p>
										Sofern nicht ausdrücklich anders vereinbart, ist der Erfüllungsort für alle Verpflichtungen aus oder im Zusammenhang mit dem Nutzungsvertrag der Geschäftssitz des Anbieters.
									</p>
									<p>
										Der Anbieter ist berechtigt, nach eigenem Ermessen Erfüllungsgehilfen und sonstige Dritte zur Erbringung der vereinbarten Leistungen einzuschalten (z.B. Software-Entwickler und Webmaster sowie Grafiker). Der Anbieter haftet für deren Handlungen und Unterlassungen wie für eigenes Verhalten. Auf Anfrage stellt der Anbieter dem Nutzer eine Liste der jeweils aktuell eingebundenen Subunternehmer zur Verfügung.
									</p>
									<p>
										Das Programm ist auf die Anwendung eines Computers/Laptop konzipiert bzw. optimiert worden. Die Anwendung auf einem Tablet (z.B. iPad) ist grundsätzlich möglich, es kann jedoch zu möglicherweise subjektiven Einschränkungen der <q>Usibilty</q> kommen.
									</p>
								</li>
								<li>
									<p className="fw-bold">Nutzungsvertrag, Nutzungsdauer und Beendigung</p>
									<p>
										Die Einlösung des Tokens stellt das Angebot des Nutzers auf Abschluss eines Nutzungsvertrags dar. Der Anbieter ist nicht verpflichtet, das Angebot des Nutzers anzunehmen. Indem der Anbieter die Einlösung des Tokens auf der Plattform akzeptiert und dem Nutzer die Nutzung der Schreibhilfe freischaltet, kommt der entsprechende Vertrag zwischen Nutzer und Anbieter über die zeitweise Nutzung der Schreibhilfe nach diesen Nutzungsbedingungen (nachfolgend <q className="fw-bold">Nutzungsvertrag</q>) zustande. Diese Nutzungsbedingungen sind damit Bestandteil des Nutzungsvertrages.
									</p>
									<p>
										Die Nutzung der Schreibhilfe ist ab diesem Zeitpunkt für die Dauer der Gültigkeit des Tokens möglich. Die Dauer der Gültigkeit ergibt sich aus den Angaben beim Erwerb des Tokens im Webshop unter <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a>. Alle vom Anbieter zur Erfüllung des Nutzungsvertrages zur Verfügung zu gestellten Leistungen und Nutzungsrechte sind mit dem Einlösen und Akzeptieren des gültigen Tokens vollständig abgegolten.
									</p>
									<p>
										Mit Ablauf dieses Zeitraums enden der Nutzungsvertrag und damit auch die Nutzungsmöglichkeit des Tokens und der Schreibhilfe automatisch. Für eine darüberhinausgehende Nutzung muss ein neuer Token erworben und eingelöst werden. Die auf dem Endgerät des Nutzers gespeicherten Inhalte, die er mit Hilfe der Schreibhilfe erfasst hat, bleiben vom Ende der Nutzungsberechtigung für die Schreibhilfe unberührt. Für deren Speicherung und Verfügbarkeit ist allein der Nutzer verantwortlich.
									</p>
									<p>
										Für die Nutzung der Schreibhilfe und im Zusammenhang mit dem Nutzungsvertrag kommen allein diese Nutzungsbedingungen zur Anwendung. Soweit nicht anderweitig ausdrücklich und schriftlich vom Anbieter bestätigt, kommen insbesondere keine Vertragsbedingungen des Nutzers zur Anwendung, selbst wenn der Nutzer auf diese verweist und der Anbieter dem Verweis nicht ausdrücklich widerspricht.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Pflichten des Nutzers</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Einhaltung geltender Gesetze</p>
									<p>
										Der Nutzer ist verpflichtet, die Schreibhilfe nur rechtmäßig nach Maßgabe dieser Nutzungsbedingungen und in Übereinstimmung mit dem anwendbaren Recht zu nutzen. Insbesondere steht der Nutzer dafür ein, dass er keine Schadsoftware jeglicher Art verwendet.
									</p>
								</li>
								<li>
									<p className="fw-bold">Verbot der Mehrfachnutzung</p>
									<p>
										Ab dem Zeitpunkt der Einlösung ist die Nutzung der Schreibhilfe nur für eigene berufliche Zwecke erlaubt. Es ist dem Nutzer untersagt, den Token an nicht Nutzungsberechtigte weiterzugeben oder die Schreibhilfe durch Dritte nutzen zu lassen, die nicht für eigene Zwecke den Token eingelöst haben. Gibt der Nutzer den Token entgegen der vereinbarten eigenen Nutzung an einen Dritten weiter, kann der Anbieter die Gültigkeit des Tokens für den Nutzer noch im Gültigkeitszeitraum sperren. (s. auch Ziff. II. 6)
									</p>
								</li>
								<li>
									<p className="fw-bold">Sicherungspflichten des Nutzers</p>
									<p>
										Der Nutzer ist - neben einer zuverlässigen Internetverbindung - stets selbst und allein für die Sicherung der mit Hilfe der Schreibhilfe erfassten Inhalte verantwortlich. (Die Schreibhilfe selber speichert keine Daten  automatisch, bzw. die Schreibhilfe speichert auch keine Daten auf einem Server (Ausführliches s. <a href="https://www.psychodynamik-navi.de/privacy" target="_blank" rel="noreferrer">Datenschutzerklärung</a>), sondern hilft lediglich bei der Erstellung eines vom Nutzer generierten Berichts.) Für die Sicherung und Verfügbarkeit eines (angefangenen sowie fertigen) Berichts ist ebenfalls allein der Nutzer verantwortlich.
									</p>
									<p>
										Speicheroption 1: Bericht generieren (am Ende oder vorzeitig) als Textdatei:
									</p>
									<p>
										Die im Programm vorgenommenen Eintragungen werden am Ende der Schrittabfolge durch Bedienung der Taste <q>Bericht generieren</q> in einer auf dem Rechner des Nutzers erstellten Textverarbeitungsdatei (dies ist eine .docx-Datei, wie man diese z.B. im Word-Programm und anderen öffnen kann) gespeichert. Diese Datei wird an keinen Server gesendet, sondern befindet sich nur lokal auf dem Rechner des Nutzers. Darüberhinaus hat der Nutzer die Möglichkeit vor Ende des letzten Schrittes die angefangenen Eintragungen vorzeitig in eine Textdatei zu speichern. Hierfür kann der Nutzer jederzeit im Prozess auf <q>Bericht vorzeitig abschließen</q> klicken. 
									</p>
									<p>
										Speicheroption 2: Zwischenspeichern mit Hilfe der Export- und Import-Funktion:
									</p>
									<p>
										Der Nutzer hat die Möglichkeit im aktiven Nutzungsprozess eine angefangene Berichtserstellung zwischenzuspeichern. Hierfür steht dem Nutzer die <q>Export</q>-Funktion im Programm zur Verfügung. Hierbei wird eine .json-Datei erstellt, die das Programm lokal (!) automatisch auf dem Rechner des Nutzers speichert. Im aktivierten Programm kann der Nutzer über die Schaltfläche <q>Import</q> auf diese .json-Datei wieder zugreifen, so dass er die dort abspeicherten Daten wieder ins Programm einfügen (importieren) kann. 
									</p>
									<p>
										Vorbehaltlich gesetzlicher Aufbewahrungspflichten ist der Anbieter berechtigt, mit dem Ende des Nutzungsvertrages sämtliche in seinem Verantwortungsbereich gespeicherten Daten des Nutzers ohne vorherige gesonderte Warnung oder Hinweis zu löschen.
									</p>
									<p>
										Der Nutzer trifft in eigener Verantwortung alle notwendigen Vorkehrungen für den Fall, dass die Schreibhilfe ganz oder teilweise nicht funktionieren oder nicht zur Verfügung stehen sollte.
									</p>
								</li>
								<li>
									<p className="fw-bold">Einstandspflicht für Dritte</p>
									<p>
										Soweit der Nutzer bei der Verwendung der Schreibhilfe oder im Zusammenhang mit dem Nutzungsvertrag berechtigterweise und gemäß diesen Nutzungsbedingungen Dritte, eigene Organe oder sonstige Beauftragte und Erfüllungsgehilfen einsetzt, haftet er für deren Verhalten und Unterlassen wie für eigenes Verhalten und Unterlassen.
									</p>
								</li>
								<li>
									<p className="fw-bold">Hinweispflichten des Nutzers</p>
									<p>
										Soweit der Nutzer bei der Verwendung der Schreibhilfe Fehler der Schreibhilfe feststellt, wird er diese unverzüglich dem Anbieter über die Mailadresse <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>  melden.
									</p>
								</li>
								<li>
									<p className="fw-bold">Pflichtverletzungen des Nutzers</p>
									<p>
										Versäumnisse des Nutzers nach den Nutzungsbedingungen, insbesondere bezüglich seiner Pflichten gem. dieser Ziff. II, werden dem Nutzer als Mitverschulden zugerechnet und führen unter anderem zu einer Befreiung des Anbieters von seinen entsprechenden Leistungspflichten für die Dauer des Versäumnisses.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Inhalte (geistiges Eigentum)</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Rechte an der Schreibhilfe sowie den grafischen Abbildungen</p>
									<p>
										Mit dem Zustandekommen des Nutzungsvertrages erhält der Nutzer ein nicht ausschließliches, nicht unterlizenzierbares, nicht übertragbares und jederzeit widerrufliches Recht zur weltweiten Nutzung der Schreibhilfe einschließlich der darüber abrufbaren Inhalte für die Dauer des Nutzungsvertrags und zu eigenen beruflichen Zwecken. Der Nutzer ist jedoch selbst dafür verantwortlich, für den Fall, dass er Inhalte (auch solche vom Anbieter selbst) teilt, kopiert oder auf sonstige Weise verwertet oder wiedergibt, alle hierfür erforderlichen Rechte einzuholen. Der Anbieter räumt ausdrücklich nur diejenigen Rechte an der Plattform ein, die notwendig sind, um die Schreibhilfe im Rahmen dieser Nutzungsbedingungen zu nutzen. Alle im Psychodynamik-Navi Programm angezeigten Inhalte, Texte sowie Methoden etc. sind geistiges Eigentum des Anbieters Ingo Jungclaussen. Die Nutzungsrechte der Abbildungen (Grafiken und Cartoons) liegen ebenso beim Anbieter. Die Nutzung der Abbildungen (Grafiken und Cartoons) außerhalb des Programms sind ohne Anfrage und Zustimmung des Anbieters untersagt.
									</p>
								</li>
								<li>
									<p className="fw-bold">Rechte an eigenen Inhalten des Nutzers</p>
									<p>
										Da die Speicherung und Verarbeitung eigener Inhalte des Nutzers bei der Verwendung der Schreibhilfe ausschließlich auf dem vom Nutzer eingesetzten Endgerät erfolgt, ist eine Rechteeinräumung an diesen Inhalten des Nutzers zu Gunsten des Anbieters nicht notwendig. Soweit technisch im Rahmen der bestimmungsgemäßen Verwendung der Schreibhilfe doch noch eine Speicherung von Inhalten des Nutzers im Einflussbereich des Anbieters möglich ist und erfolgen sollte, räumt der Nutzer dem Anbieter bereits jetzt an diesen Inhalten das nicht ausschließliche, nicht unterlizenzierbare, nicht übertragbare und jederzeit widerrufliche Recht zur weltweiten Nutzung der Inhalte des Nutzers für die Dauer des Nutzungsvertrages und eventueller gesetzlicher Aufbewahrungspflichten ein.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Verbote</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Veröffentlichungs- und Verwertungsverbot</p>
									<p>
										Es ist nicht gestattet, den Token oder die Schreibhilfe Dritten gegenüber zu veröffentlichen, leih- oder mietweise zur Verfügung zu stellen, zu verkaufen oder anderweitig kommerziell zu verwerten. (Anderenfalls kann die Gültigkeit eines Tokens vor Ablauf deaktiviert werden. Der Anbieter behält sich grundsätzlich die Möglichkeit offen, etwaige Nutzer von der Nutzung des Programms auszuschließen). Davon ausdrücklich ausgenommen ist die Nutzung der Schreibhilfe für eigene Zwecke zur Erstellung von Psychotherapieanträgen und der darauffolgenden Nutzung der Anträge im Rahmen der eigenen beruflichen Zwecke.
									</p>
								</li>
								<li>
									<p className="fw-bold">Änderungsverbote</p>
									<p>
										Es ist nicht gestattet, die Schreibhilfe zu ändern, anzupassen, zu übersetzen, abgeleitete Arbeiten daraus zu erstellen, zu dekompilieren, zurück zu entwickeln, zu disassemblieren oder anderweitig zu versuchen den Quellcode der Schreibhilfe abzuleiten. Gesetzliche Befugnisse bleiben unberührt, insbesondere das Recht des Nutzers, die Dienste zu dekompilieren, um die erforderlichen Informationen zur Herstellung einer Interoperabilität mit anderen Programmen zu erhalten, sofern der Anbieter ihm diese nicht zu angemessenen Bedingungen zugänglich macht.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Verstoß</p>
							<p>
								Unbeschadet aller sonstigen gesetzlichen oder vertraglichen Rechte, insbesondere des Rechts zur Kündigung aus wichtigem Grund und der Geltendmachung von Ansprüchen auf Unterlassung der Nutzung der Schreibhilfe und angemessenem Ersatz von Schäden, die dem Anbieter kausal durch das Verhalten oder Unterlassen des Nutzers entstanden sind, kann der Anbieter nach billigem Ermessen eine oder mehrere der folgenden Maßnahmen ergreifen, wenn tatsächliche Anhaltspunkte dafür bestehen, dass ein Nutzer gegen diese Nutzungsbedingungen oder die jeweils anwendbaren ergänzenden Bedingungen verstoßen hat, es sei denn, der Nutzer hat den Vorstoß nicht zu vertreten: 
							</p>
							<ol type="a">
								<li>
									vorübergehende oder dauerhafte Deaktivierung des Tokens und damit der Nutzbarkeit der Schreibhilfe
								</li>
								<li>
									Rücknahme des Tokens und damit dauerhafte Nutzungsuntersagung für die Schreibhilfe
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">VI.	Gewährleistung</p>
							<p>
								Der Anbieter stellt die Schreibhilfe in der beim Einlösen des Tokens auf der Plattform verfügbaren Form mit den darin enthaltenen Funktionen und Elementen zur Verfügung. Dem Nutzer ist bekannt, dass die Verwendbarkeit der Schreibhilfe entscheidend vom Vorliegen der technischen Voraussetzungen dafür auf seinem Endgerät abhängig ist. Für deren Vorliegen, wie auf der Plattform angegeben, ist allein der Nutzer verantwortlich.
							</p>
							<p>
								Der Anbieter steht darüberhinaus bezüglich der Plattform und Schreibhilfe weder für deren Verfügbarkeit noch für bestimmte Funktionen oder deren Verwendbarkeit für Zwecke des Nutzers ein, die nicht nach diesen Nutzungsbedingungen zugesagt sind. Garantieversprechen werden nicht gegeben.
							</p>
							<p>
								Der Anbieter darf jederzeit nach vernünftigem Ermessen unter Berücksichtigung der berechtigten Interessen des Nutzers ggf. auch ohne vorherige Ankündigung den Zugang zur Schreibhilfe ganz oder teilweise einschränken sowie nach eigenem Ermessen die Inhalte der Schreibhilfe festlegen, ändern, erweitern oder entfernen bzw. löschen. Diese Änderungen werden jeweils erst mit Ablauf der Gültigkeit bereits eingelöster Token wirksam, es sei denn eine sofortige Anpassung ist nach Ermessen des Anbieters notwendig, um zwingende rechtliche Vorgaben, veränderte technische Anforderungen oder die Vermeidung der Verletzung Rechte Dritter umzusetzen.
							</p>
							<p>
								Soweit die Schreibhilfe Mängel aufweist, kommen die &sect;&sect; 535ff. BGB zur Anwendung. Die Haftung des Anbieters gem. &sect; 536a Abs. 1 BGB für Verwendbarkeitseinschränkungen oder Fehler, die bei Zurverfügungstellung der Schreibhilfe und Einlösen des Tokens bereits vorhanden waren, ist ausgeschlossen.
							</p>
							<p>
								Der Anbieter verpflichtet sich inhaltliche oder technische Mängel oder Fehler Schreibhilfe im Rahmen der vereinbarten Gewährleistungsverpflichtungen nach bestem Wissen umgehend zu beseitigen und hierüber den Nutzer auf der Web-Seite in angemessener und nachvollziehbarer Weise zu informieren bzw.  eine Umgehungslösung aufzuzeigen, soweit dies dem Nutzer unter Berücksichtigung der Auswirkungen des Mangels und der Umstände der aufgezeigten Umgehungslösung zumutbar ist. 
							</p>
							<p>
								Der Anbieter gibt weder eine Gewährleistung über die Dauer der Berichtserstellung noch über die Qualität eines Berichts sowie dessen Bewilligung im Rahmen des Gutachterverfahrens. Das Programm stellt nur eine technische Hilfe zum Verfassen des Berichts dar: Die Verantwortung über den zeitlichen Aufwand sowie über den Inhalt der Eintragungen liegt beim Nutzer. 
							</p>
							<p>
								Gewährleistungsansprüche des Nutzers bestehen nur bezüglich vom Anbieter reproduzierbarer Mängel.
							</p>
							<p>
								Ist die vertragsgemäße Nutzung der Schreibhilfe durch den Nutzer während der Dauer des Nutzungsvertrages aufgrund von Schutzrechten Dritter beeinträchtigt, kann der Anbieter den Mangel nach eigener Wahl beseitigen, indem er entweder
								<ol type="a">
									<li>dem Nutzer die für die Nutzung erforderlichen Rechte verschafft; oder</li>
									<li>die Schreibhilfe so abändert, dass Schutzrechte Dritter durch die vertragsgemäße Nutzung durch den Nutzer nicht weiter beeinträchtigt werden, ohne dass wesentliche Funktionen der Schreibhilfe beeinträchtigt werden.</li>
								</ol>
								Ist nach Auffassung des Anbieters keine der beiden vorgenannten Varianten mit wirtschaftlich vertretbarem Aufwand realisierbar, ist der Anbieter zur vollständigen oder teilweisen außerordentlichen Kündigung des Nutzungsvertrages ohne Einhaltung einer Frist berechtigt.
							</p>
							<p>
								Die Rechte des Nutzers wegen Mängeln der Schreibhilfe sind ausgeschlossen, soweit der Nutzer ohne Zustimmung des Anbieters Änderungen an der Schreibhilfe vornimmt oder durch andere vornehmen lässt, es sei denn, der Nutzer weist nach, dass die Änderungen keine für den Anbieter unzumutbaren Auswirkungen auf die Analyse und/oder Fehlerbeseitigung haben. Die Rechte des Nutzers wegen Mängeln der Schreibhilfe bleiben unberührt, soweit der Nutzer zu Änderungen, insbesondere im Rahmen der Ausübung des Selbstvornahmerechts nach &sect; 536a Abs. 2 BGB berechtigt ist und diese Änderungen fachgerecht durchgeführt und nachvollziehbar dokumentiert wurden.
							</p>
							<p>
								Macht der Nutzer gegenüber dem Anbieter Mängelansprüche geltend, obwohl kein Mangel der Schreibhilfe vorliegt, ist der Nutzer verpflichtet, dem Anbieter die dadurch entstandenen Aufwendungen und Kosten zu ersetzen, es sei denn, der Nutzer hat den unberechtigten Gewährleistungsanspruch nicht zu vertreten.
							</p>
							<p>
								Für etwaige Schadenersatzforderungen aufgrund von Mängeln der Schreibhilfe ist Ziff. VII anwendbar.
							</p>
						</li>
						<li>
							<p className="fw-bold">Haftung des Anbieters</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Haftung des Anbieters</p>
									<p>
										Der Anbieter haftet entsprechend der gesetzlichen Vorgaben unbeschränkt bei Vorsatz oder grober Fahrlässigkeit, für die Verletzung von Leben, Leib oder Gesundheit, nach den Vorschriften des Produkthaftungsgesetzes sowie im Umfang einer vom Anbieter andernorts ausdrücklich übernommenen Garantie.
									</p>
								</li>
								<li>
									<p className="fw-bold">Haftungsausschluss</p>
									<p>
										Ausgeschlossen ist eine Haftung des Anbieters, seiner gesetzlichen Vertreter, Erfüllungsgehilfen und Betriebsangehöriger für durch leichte Fahrlässigkeit verursachte Schäden, ausgenommen nach vorgenannter Ziffer 1.
									</p>
									<p>
										Dies gilt nicht bei der Verletzung von wesentlichen Pflichten. Wesentlich sind Pflichten, (i) deren Verletzung die Erfüllung des Nutzungszwecks gefährdet oder (ii) die die ordnungsgemäße Durchführung der Nutzung überhaupt erst ermöglichen und auf deren Einhaltung die andere Partei regelmäßig vertrauen darf. In diesem Fall haftet der Anbieter jedoch nur für den vorhersehbaren, vertragstypischen Schaden.
									</p>
									<p>
										Der Anbieter haftet nicht für etwaige Schäden jeder Art, wenn ein Nutzer während der Anwendung der Schreibhilfe alle seine Text-Eintragungen z.B. aufgrund fehlender Speicherung verlieren sollte.
									</p>
									<p>
										Die Methode des Programms besteht in der Strukturierung, Portionierung und Didaktisierung des Schreibprozesses. Die Verantwortung über Art und Inhalt des Berichts obliegt dem Nutzer. 
									</p>
									<p>
										Der Anbieter haftet somit nicht für etwaige Schäden oder Probleme jeder Art im Zusammenhang des Gutachterverfahrens (Beantragung der Psychotherapie), z.B. Ablehnungen, Kürzungen des Stundenkontingents sowie möglichen Nachbesserungen des Berichts. 
									</p>
									<p>
										Die Zielsetzung des Programms – als digitale Schreib- und Strukturierungshilfe für den Bericht an den Gutachter- kann ohne Verwendung von Patientendaten, wie Namen, Chiffre bzw. Geburtsdatum, Adressen, Telefonnummer, Krankenkasse, Versicherungsnummer etc. erreicht werden.  Der Anbieter haftet somit nicht für etwaige Schäden jeder Art im Kontext von Patientendaten, die sich im Zusammenhang einer Nutzung des Programms ereignen können.
									</p>
									<p>
										Die im Programm der Schreibhilfe Psychodynamik-Navi aufgeführten Informationen berühren wichtige (gesetzliche) Regelungen. Diese wurden vom Anbieter mit bestem Wissen und hoher Sorgfalt geprüft. Dennoch übernimmt der Anbieter weder die Gewähr für diese Angaben noch die Haftung für negative Konsequenzen, die sich aus ihrer (fehlerhaften) Anwendung ergeben könnten. Es gelten unabhängig von den hier aufgeführten Angaben die jeweils aktuell gültigen Psychotherapie-Richtlinien bzw. -Vereinbarungen sowie ihr Kommentar. Der Nutzer ist (als Psychotherapeut) verpflichtet sich hierüber selbständig über Art und Änderungen dieser Bestimmungen zu informieren. Rechtsauskünfte zu den betreﬀenden Themengebieten erteilt z. B. die Kassenärztliche Bundesvereinigung. Zudem wird bzgl. juristisch-rechtlicher Auskünfte an die Rechtsabteilung der jeweiligen psychologischen/psychotherapeutischen Berufs- bzw. Fachverbandes verwiesen. Das Psychotherapiewesen sowie das Gutachterverfahren unterliegen einem fortschreitenden Entwicklungsprozess mit in seiner Bewertung wechselnden (berufspolitischen sowie individuellen) Einschätzungen. Somit können alle Angaben und Informationen immer nur dem Wissens- und Entstellungsstand zum Zeitpunkt der Programmierung entsprechen.
									</p>
									<p>
										Das Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.
									</p>
								</li>
								<li>
									<p className="fw-bold">Haftungsfreistellung</p>
									<p>
										Wird der Anbieter von einem Dritten wegen eines Verstoßes des Nutzers gegen diese Nutzungsbedingungen in Anspruch genommen, stellt der Nutzer den Anbieter von sämtlichen Ansprüchen und Kosten frei, die dadurch unmittelbar oder mittelbar entstehen einschließlich angemessener Kosten zur Verteidigung durch einen Anwalt. Dies gilt nicht, soweit der Nutzer einen Verstoß nicht zu vertreten hat. Der Anbieter behält sich vor, die Verteidigung gegen solche Ansprüche selbst zu übernehmen.
									</p>
								</li>
							</ol>
						</li>
						<li>
							<p className="fw-bold">Datenschutz</p>
							<p>
								Neben diesen Nutzungsbedingungen gelten Datenschutzhinweise des Anbieters unter <a href="https://psychodynamik-navi.de/privacy" target="_blank" rel="noreferrer">https://psychodynamik-navi.de/privacy</a>. Diese sind nicht Bestandteil dieser Nutzungsbedingungen, sondern beschreiben, wie der Anbieter personenbezogene Daten verarbeitet.
							</p>
						</li>
						<li>
							<p className="fw-bold">Vertragsänderung und -beendigung</p>
							<p>
								Der Anbieter behält sich vor, diese Nutzungsbedingungen zu ändern. Der Anbieter wird dem Nutzer z.B. per Popup-Fenster (im Programm) in inhaltlich, technisch und zeitlich geeigneter Form die Änderungen zukommen lassen und ihn auf eine beabsichtigte Geltung dieser neuen Nutzungsbedingungen auch für einen laufenden Vertrag sowie das Recht des Nutzers, der Geltung dieser Nutzungsbedingungen zu widersprechen, hinweisen. Widerspricht der Nutzer der Geltung der neuen Nutzungsbedingungen nicht oder nutzt die Inhalte des Angebots, nachdem die geänderten Nutzungsbedingungen in Kraft getreten sind, gelten die neuen Nutzungsbedingungen als akzeptiert.
							</p>
						</li>
						<li>
							<p className="fw-bold">Allgemeines</p>
							<ol type="1">
								<li>
									<p className="fw-bold">Unwirksamkeit einzelner Klauseln</p>
									<p>
										Sollten einzelne Bestimmungen dieser Nutzungsbedingungen ungültig oder undurchsetzbar sein oder werden, berührt dies die Wirksamkeit der übrigen Bestimmungen nicht. Die Parteien werden sich bemühen, die unwirksame Bestimmung durch eine wirksame Bestimmung zu ersetzen, die dem Sinn und wirtschaftlichen Zweck der unwirksamen Bestimmung und dem Willen der Parteien am nächsten kommt. Gleiches gilt für ungewollte Lücken.
									</p>
								</li>
								<li>
									<p className="fw-bold">Abtretbarkeit der Ansprüche</p>
									<p>
										Der Nutzer ist nicht berechtigt, seine Ansprüche oder Rechte aus oder im Zusammenhang mit dem Nutzungsvertrag abzutreten. &sect; 354a HGB bleibt davon unberührt.
									</p>
								</li>
								<li>
									<p className="fw-bold">Anwendbares Recht</p>
									<p>
										Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts. Gerichtsstand für Streitigkeiten aus oder im Zusammenhang mit diesen Bedingungen sind die Gerichte von Düsseldorf, wenn der Nutzer keinen Sitz in Deutschland hat oder Kaufmann ist.
									</p>
								</li>
							</ol>
						</li>
					</ol>
				</div>
			</Container>
		</>
	);
}