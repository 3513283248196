import merge from "lodash.merge";
import cloneDeep from "lodash.clonedeep";

import { isChecklistItemTask } from "../models/ChecklistItemTask";
import ChecklistPage from "../models/ChecklistPage";

export function stripChecklistData(pages: ChecklistPage[]) {
	let strippedPages: any[] = [];

	for(let page of pages) {
		let strippedPage: any = {
			key: page.key,
			nextPage: page.nextPage,
			prevPage: page.prevPage
		};

		if(page.items) {
			strippedPage.items = [];

			for(let item of page.items) {
				let strippedItem: any = {
					key: item.key,
					nextItem: item.nextItem,
					prevItem: item.prevItem
				};

				if(item.items) {
					strippedItem.items = [];

					for(let itemItem of item.items) {
						let strippedItemItem: any = {
							key: itemItem.key
						};

						if(isChecklistItemTask(itemItem)) {
							strippedItemItem.textInput = itemItem.textInput;
						}

						strippedItem.items.push(strippedItemItem);
					}
				}

				strippedPage.items.push(strippedItem);
			}
		}

		strippedPages.push(strippedPage);
	}

	return strippedPages;
}

export function mergeChecklistData(checklist: ChecklistPage[], strippedChecklist: any[]) {
	let clone = cloneDeep(checklist);
	return merge(clone, strippedChecklist);
}