import { marked } from "marked";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DOMPurify from "dompurify";
import ChecklistItemContent from "../../models/ChecklistItemContent";

import styles from "./AppItemTabs.module.scss";

export interface AppItemTabsProps {
	item: ChecklistItemContent;
}

export default function AppItemTabs(props: AppItemTabsProps) {
	const [selected, setSelected] = useState("");

	const variant = (key: string) => selected === key ? "primary" : "light";
	const select = (key: string) => key === selected ? setSelected("") : setSelected(key);

	const htmlContent = () => {
		switch(selected) {
			case "help":
				return marked(props.item.help!);
			case "examples":
				return marked(props.item.examples!);
			case "common_mistakes":
				return marked(props.item.common_mistakes!);
			case "checklist":
				return marked(props.item.checklist!);
			case "literature":
				return marked(props.item.literature!);
			default:
				return ""
		}
	}

	return (
		<div className="my-2">
			<Row className="row-cols-auto">
				{props.item.help &&
					<Col><Button variant={variant("help")} onClick={() =>select("help")}>Wissenswertes/Tipps</Button></Col>
				}
				{props.item.examples &&
					<Col><Button variant={variant("examples")} onClick={() =>select("examples")}>Formulierungsbsp.</Button></Col>
				}
				{props.item.common_mistakes &&
					<Col><Button variant={variant("common_mistakes")} onClick={() =>select("common_mistakes")}>Häufige Fehler</Button></Col>
				}
				{props.item.checklist &&
					<Col><Button variant={variant("checklist")} onClick={() =>select("checklist")}>Checkliste</Button></Col>
				}
				{props.item.literature &&
					<Col><Button variant={variant("literature")} onClick={() =>select("literature")}>Literatur- / Internet-Empfehlungen</Button></Col>
				}
			</Row>
			{selected !== "" && 
				<div className={`markdown-body border my-3 p-2 ${styles.disableSelection}`} unselectable="on" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(htmlContent())}}></div>
			}
		</div>
	);
}