import ChecklistItem from "../models/ChecklistItem";
import { isChecklistItemImage } from "../models/ChecklistItemImage";
import ChecklistPage from "../models/ChecklistPage";

export function findPageByItem(pages: ChecklistPage[], itemKey: string) {
	return pages.find(p => p.items && p.items.some(i => i.key === itemKey));
}

export function getPage(pages: ChecklistPage[], key: string): (ChecklistPage | undefined) {
	return pages.find(p => p.key === key);
}

export function getItem(pages: ChecklistPage[], pageKey: string, itemKey: string) {
	const page = pages.find(p => p.key === pageKey);

	if (page) {
		return page.items.find(i => i.key === itemKey);
	}

	return undefined;
}

export function getNextPage(pages: ChecklistPage[], pageKey: string): (ChecklistPage | undefined) {
	const page = getPage(pages, pageKey);

	if (page && page.nextPage) {
		return getPage(pages, page.nextPage);
	}

	return undefined;
}

export function getPrevPage(pages: ChecklistPage[], pageKey: string) {
	const page = getPage(pages, pageKey);

	if (page && page.prevPage) {
		return getPage(pages, page.prevPage);
	}

	return undefined;
}

export function getNextItem(pages: ChecklistPage[], pageKey: string, itemKey: string) {
	const item = getItem(pages, pageKey, itemKey);

	if (item) {
		if (item.nextItem) {
			return getItem(pages, pageKey, item.nextItem);
		} else {
			const nextPage = getNextPage(pages, pageKey);

			if (nextPage) {
				return nextPage.items[0]
			}
		}
	}

	return undefined;
}

export function getPrevItem(pages: ChecklistPage[], pageKey: string, itemKey: string) {
	const item = getItem(pages, pageKey, itemKey);

	if (item) {
		if (item.prevItem) {
			return getItem(pages, pageKey, item.prevItem);
		} else {
			const prevPage = getPrevPage(pages, pageKey);

			if (prevPage) {
				return prevPage.items[prevPage.items.length - 1];
			}
		}
	}

	return undefined;
}

export function isLastItemOfPage(pages: ChecklistPage[], pageKey: string, itemKey: string) {
	const page = getPage(pages, pageKey);

	if (page && page.items) {
		return page.items[page.items.length - 1].key === itemKey;
	}

	return false;
}

export function isLastItem(pages: ChecklistPage[], pageKey: string, itemKey: string) {
	const lastPage = pages[pages.length - 1]

	if (pageKey === lastPage.key) {
		const lastItem = lastPage.items[lastPage.items.length - 1];

		return itemKey === lastItem.key;
	}

	return false;
}

export function getNextLink(pages: ChecklistPage[], item: ChecklistItem, showImages: boolean): string | undefined {
	const curPage = findPageByItem(pages, item.key);

	if (curPage) {
		if (isLastItem(pages, curPage.key, item.key)) {
			return "/app/finished";
		}

		const nextItem = getNextItem(pages, curPage.key, item.key);

		if (nextItem) {
			const nextItemPage = findPageByItem(pages, nextItem.key);

			if (nextItemPage) {
				// skip item if images should not be shown
				if (isChecklistItemImage(nextItem) && !showImages) {
					return getNextLink(pages, nextItem, showImages);
				}

				if (nextItemPage.key.includes("{{")) {
					return undefined;
				}

				return `/app/page/${nextItemPage.key}/${nextItem.key}`;
			}
		}
	}

	return undefined;
}

export function getPrevLink(pages: ChecklistPage[], item: ChecklistItem, showImages: boolean): string | undefined {
	const curPage = findPageByItem(pages, item.key);

	if (curPage) {
		const prevItem = getPrevItem(pages, curPage.key, item.key);

		if (prevItem) {
			// skip item if images should not be shown
			if (isChecklistItemImage(prevItem) && !showImages) {
				return getPrevLink(pages, prevItem, showImages);
			}

			const prevItemPage = findPageByItem(pages, prevItem.key);

			if (prevItemPage) {

				if (prevItemPage.key.includes("{{")) {
					return undefined;
				}

				return `/app/page/${prevItemPage.key}/${prevItem.key}`;
			}
		}
	}

	return undefined;
}