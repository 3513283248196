import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Training() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Fortbildungspunkte</h1>
					<p>
						Perspektivisch ist eine Fortbildungs-Version dieses Programms geplant. D.h. Sie werden die Möglichkeit erhalten, Ihr Wissen aus den Fachtexten (die Sie begleitend im Programm an den einzelnen Schritten erhalten) mit Hilfe einer Wissensüberprüfung abzufragen. So können Sie beim Bestehen der Wissensüberprüfung entsprechende zertifizierte Fortbildungspunkte erwerben. Nähere Informationen zu den verschiedenen Programmversionen entnehmen Sie <Link to="/upgrades">hier</Link>.
					</p>
					<p>
						Auch wenn Sie über diesen Punkt regelmäßig informiert werden möchte, empfehle ich Ihnen den Eintrag in meinem Newsletter. Infos <Link to="/newsletter">hier</Link>
					</p>
				</div>
			</Container>
		</>
	);
}