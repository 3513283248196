import { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useParams } from "react-router";
import ChecklistItem from "../../models/ChecklistItem";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectPage, selectPages, setDone, showImagePages, populate } from "../../redux/pages/pagesSlice";
import { getNextLink, getPrevLink, isLastItemOfPage } from "../../utils/pageUtils";
import AppItem from "../appItem/AppItem";
import AppSidebar from "../appSidebar/AppSidebar";
import ChecklistService from "../../services/ChecklistService";

import styles from "./AppPage.module.scss";

type RoutingParams = Record<"pageKey" | "itemKey", string>;

export default function AppPage() {
	const { pageKey, itemKey } = useParams<RoutingParams>();
	const page = useAppSelector(state => selectPage(state, pageKey || ""));
	const pages = useAppSelector(selectPages);
	const showImages = useAppSelector(showImagePages);
	const dispatch = useAppDispatch();

	useEffect(() => {
		const checklistService = new ChecklistService();
		checklistService.loadChecklist()
			.then((checklistData: any) => {
				dispatch(populate(checklistData));
			});
	}, [dispatch]);

	if (page) {
		const item = itemKey ? page.items.find(item => item.key === itemKey) : page.items[0]

		const nextLink = getNextLink(pages, item!, showImages);
		const prevLink = getPrevLink(pages, item!, showImages);

		const navigateNext = (item: ChecklistItem) => {
			const isLastOfPage = isLastItemOfPage(pages, page.key, item.key);

			if (isLastOfPage) {
				dispatch(setDone(page.key));
			}

			if (nextLink) {
				window.location.href = nextLink;
			}
		}

		if (item) {
			return (
				<>
					<div className="d-flex flex-grow-1 flex-column">
						<Row className="flex-fill">
							<Col lg={3} xs={12} className="border-end mt-2">
								<AppSidebar page={page} item={item!} />
							</Col>
							<Col>
								{page.title &&
									<h2 className={`mx-3 mb-4 ${styles.pageTitle}`}>{page.title}</h2>
								}
								<AppItem page={page} item={item!} />
							</Col>
						</Row>
						<Row>
							<Col>
								{prevLink &&
									<a href={prevLink} className="btn btn-primary float-start">Zurück</a>
								}
								{nextLink &&
									<Button className="float-end" variant="primary" onClick={() => navigateNext(item)}>Weiter</Button>
								}
							</Col>
						</Row>
					</div>
				</>
			);
		}
	}

	return (null);
}