import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import ChecklistItem from "../../models/ChecklistItem";
import ChecklistItemTask from "../../models/ChecklistItemTask";
import ChecklistPage from "../../models/ChecklistPage";
import { useAppDispatch } from "../../redux/hooks";
import { setTextInput } from "../../redux/pages/pagesSlice";

import styles from "./AppItemTask.module.scss";

export interface AppItemTaskProps {
	page: ChecklistPage;
	item: ChecklistItem;
	taskItem: ChecklistItemTask;
}

export default function AppItemTask(props: AppItemTaskProps) {
	const dispatch = useAppDispatch();
	const [text, setText] = useState(props.taskItem.textInput || "");

	useEffect(() => {
		dispatch(setTextInput([props.page.key, props.item.key, props.taskItem.key, text || ""]));
	}, [text, props.page.key, props.item.key, props.taskItem.key, dispatch]);

	DOMPurify.addHook('afterSanitizeAttributes', function (node) {
		if (node.tagName.toLowerCase() === "a") {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener");
		}
	});

	return (
		<>
			{props.taskItem.annotation_pre && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.taskItem.annotation_pre) }}></p>
			)}

			{props.taskItem.tasks.map((task, idx) => {
				return (
					<p key={idx} className={styles.taskText}><b>Aufgabe{props.taskItem.optional ? " (optional)" : ""}: </b> <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(task) }}></span></p>
				);
			})}

			{props.taskItem.annotation_in && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.taskItem.annotation_in) }}></p>
			)}

			<div className="pt-3 fs-5">
				<Form.Control as="textarea" rows={10} defaultValue={text} onKeyUp={ev => setText(ev.currentTarget.value)} />
			</div>

			{props.taskItem.annotation_post && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.taskItem.annotation_post) }}></p>
			)}
		</>
	);
}