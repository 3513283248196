import { Container } from "react-bootstrap";

export default function Newsletter() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Newsletter</h1>
					<img className="d-block m-auto mb-3 pt-3" src="/psy_dak_logo_circle.png" alt="Logo" />
					<p>
						Wenn Sie regelmäßig u.a. über die neueren Programm-Erweiterungen (Upgrades) informiert werden möchten, tragen Sie sich bitte hierfür in meinen Psy-Dak-Newsletter ein.
						<br/>
						In diesem Newsletter informiere ich Sie auch über mein Fortbildungsangebot, in welchem auch die Leitfäden aus diesem Programm ausführlich thematisiert werden. Hier geht es zum <a href="https://www.psy-dak.de/newsletter" target="_blank" rel="noreferrer">Psy-Dak Newsletter</a>.
					</p>
					<p>
						Wenn Sie sich über mein im Aufbau befindliches E-Learning -Angebot zur Psychodynamischen Psychotherapie/Psychoanalyse informieren möchten, empfehle ich Ihnen meinen <a href="https://frag-freud.de/anmeldung-newsletter/" target="_blank" rel="noreferrer">"Frag-Freud"-Newsletter</a>.
					</p>
				</div>
			</Container>
		</>
	);
}