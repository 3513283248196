import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import ChecklistPage from '../../models/ChecklistPage'
import type { RootState } from '../store'

import ChecklistItemTask from '../../models/ChecklistItemTask';
import { ChecklistItemChoiceOption } from '../../models/ChecklistItemChoice';
import { mergeChecklistData, stripChecklistData } from '../../utils/checklistUtils';
import { VERSION_KEY } from '../../services/ChecklistService';

const STORAGE_KEY = "pages";

interface PagesState {
	pages: ChecklistPage[];
	showImagePages: boolean;
}

const save = (state: PagesState) => {
	let strippedState = {
		pages: stripChecklistData(state.pages),
		showImagePages: state.showImagePages
	};

	localStorage.setItem(STORAGE_KEY, JSON.stringify(strippedState));

	console.info("Saved data to LocalStorage")
}

const load = (checklist: ChecklistPage[]) => {
	const item = localStorage.getItem(STORAGE_KEY);

	if (item) {
		let parsed = JSON.parse(item) as PagesState;

		let state = {
			pages: mergeChecklistData(checklist, parsed.pages),
			showImagePages: parsed.showImagePages
		}

		console.info("Loaded data from LocalStorage")

		return state as PagesState;
	} else {
		return {
			pages: checklist.filter(page => !page.dependsOn),
			showImagePages: true
		};
	}
}

const initialState: PagesState = {
	pages: [],
	showImagePages: true
};

export const pagesSlice = createSlice({
	name: 'pages',
	initialState,
	reducers: {
		init: (state, action: PayloadAction<ChecklistPage[]> ) => {
			localStorage.removeItem(STORAGE_KEY);
			const initialState = load(action.payload);
			state.pages = initialState.pages;
			save(state);
		},
		clear: (state, action: PayloadAction<ChecklistPage[]>) => {
			localStorage.removeItem(STORAGE_KEY);
			const initialState = load(action.payload);
			state.pages = initialState.pages;
		},
		populate: (state, action: PayloadAction<ChecklistPage[]>) => {
			const newState = load(action.payload);
			state.pages = newState.pages;
			state.showImagePages = newState.showImagePages;
		},
		setState: (state, action: PayloadAction<string>) => {
			const loadedState = JSON.parse(action.payload) as any;

			localStorage.setItem(VERSION_KEY, loadedState.version);

			state.pages = loadedState.state.pages;
			save(state);
		},
		setDone: (state, action: PayloadAction<string>) => {
			const pageKey = action.payload;

			state.pages = state.pages.map(page => {
				return {
					...page,
					done: page.key === pageKey ? true : page.done
				};
			});

			save(state);
		},
		setTextInput: (state, action: PayloadAction<[string, string, string, string]>) => {
			const [pageKey, itemKey, taskKey, text] = action.payload;

			state.pages = state.pages.map(page => {
				if (page.key === pageKey) {
					return {
						...page,
						items: page.items.map(item => {
							if (item.key === itemKey) {
								return {
									...item,
									items: item.items.map(taskItem => {
										if (taskItem.key === taskKey) {
											(taskItem as ChecklistItemTask).textInput = text;
										}
										return taskItem;
									})
								}
							} else {
								return item;
							}
						})
					};
				} else {
					return page;
				}
			});

			save(state);
		},
		setChoicePages: (state, action: PayloadAction<ChecklistItemChoiceOption>) => {
			const option = action.payload;

			state.pages = state.pages.map(page => {
				if(option.patchPrevPageKeys.includes(page.key)) {
					page.prevPage = option.page;
				}
				if(option.patchNextPageKeys.includes(page.key)) {
					page.nextPage = option.page;
				}

				return page;
			});

			save(state);
		},
		setShowImagePages: (state, action: PayloadAction<boolean>) => {
			state.showImagePages = action.payload;
			save(state);
		}
	}
})

export const { init, clear, populate, setState, setDone, setTextInput, setChoicePages, setShowImagePages } = pagesSlice.actions

export const selectPage = (state: RootState, pageKey: string) => {
	const filtered = state.pages.pages.filter(page => page.key === pageKey);

	if (filtered.length > 0) {
		return filtered[0];
	} else {
		return state.pages.pages[0];
	}
}

export const selectPagesState = (state: RootState) => state.pages;
export const selectPages = (state: RootState) => state.pages.pages;
export const showImagePages = (state: RootState) => state.pages.showImagePages;

export default pagesSlice.reducer