import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Upgrades() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Upgrades</h1>
					<p>
						Das vorliegende Programm ist in folgenden Varianten erhältlich bzw. geplant:
						<br />
						Das Programm startet in einer Basic-Version (nur Textfelder). Es soll danach mit unterschiedlichen Zusatzfunktionen angeboten werden.
					</p>
					<p>
						Wenn Sie regelmäßig über die neuen Versionen informiert werden möchten, tragen Sie sich hier in den kostenlosen <Link to="/newsletter">Newsletter</Link> ein.
					</p>
					<ol>
						<li className="fw-bold">Basis-Version (nur Textfelder)</li>
						<p>
							Sie nutzen die reinen Textfelder mit Überschriften entlang aller Antrags-Schritte (ohne Erklärtexte und ohne Animation).
						</p>
						<li className="fw-bold">Comfort-Version (Textfelder &amp; Erklär- und Hilfstexte)</li>
						<p>
							Alle Schritte und Textfelder werden durch umfangreiche Erklärtexte ergänzt. Hierdurch erhalten Sie eine breite Hilfs- und Nachschlagefunktion. Diese Extra-Felder umfassen folgende Punkte:
						</p>
						<ul>
							<li>
								<b>Wissenswertes/Tipp</b>&nbsp;
								Sie erhalten umfangreiche Fachtexte zum jeweiligen Punkt. Diese Texte werden Sie teils an mein Handbuch erinnern, Sie erhalten auch Hinweise dazu, was der Faber/Haarstrick-Kommentar zu diesem Berichtspunkt sagt.
							</li>
							<li>
								<b>Formulierungsbeispiele</b>&nbsp;
								Hier erhalten Sie mögliche Formulierungshilfen zum jeweiligen Punkt. Der Schwerpunkt des Programms liegt jedoch nicht auf Formulierungsbausteinen.
							</li>
							<li>
								<b>Häufige Fehler</b>&nbsp;
								Hier erhalten Sie Hinweise, welche Fehler bei diesem Punkt häufig gemacht werden können.
							</li>
							<li>
								<b>Checkliste</b>&nbsp;
								Hier erhalten Sie Checklisten, mit deren Hilfe Sie die wichtigsten Anforderungen zu diesem Punkt noch einmal durchgehen können.
							</li>
							<li>
								<b>Literatur-/Internet-Empfehlungen</b>&nbsp;
								Hier erhalten Sie eine teils sehr umfangreiche Sammlung an Literaturhinweisen und Internet-Links zum jeweiligen Punkt, mit deren Hilfe Sie Ihr Fachwissen zum Punkt vertiefen können.
							</li>
						</ul>
						<p>
							Die Comfort-Version enthält keine bewegte Animation.
						</p>
						<li className="fw-bold">Premium-Version (Textfelder, Erklär- und Hilfstexte sowie Psychodynamik-Animation)</li>
						<p>
							Alle Funktionen wie Comfort-Version, mit dem Zusatz, dass alle Schritte der Psychodynamik mit einer bewegten Animation erklärt werden. Sie sehen die Animation der Psychodynamik und können Ihre Gedanken direkt in ein Textfeld hineinschreiben. So schlagen Sie 2 Fliegen mit einer Klappe: Sie werden durch die Animation fortgebildet und schreiben gleichzeitig Ihren Antrag.
						</p>
						<li className="fw-bold">Fortbildungs-Version</li>
						<p>
							Perspektivisch ist eine Fortbildungs-Version geplant. D.h. Sie werden die Möglichkeit haben, Ihr Wissen aus der Comfort- und Premium-Version mit Hilfe einer Wissensüberprüfung abzufragen, so dass Sie hierdurch bei Bestehen entsprechende zertifizierte Fortbildungspunkte erwerben können. Die Fortbildungs-Version ist mit der Comfort- und Premium -Version kombinierbar.
						</p>
					</ol>
					<p>
						Wenn Sie regelmäßig über die neuen Versionen informiert werden möchten, tragen Sie sich hier in den kostenlosen <Link to="/newsletter">Newsletter</Link> ein.
					</p>
				</div>
			</Container>
		</>
	);
}