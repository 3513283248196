import { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom"
import { Images } from "../../img/Images";

import styles from "./Home.module.scss";

export default function Home() {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);
	const [animate, setAnimate] = useState(localStorage.getItem("showAnimation") === null);
	const navigate = useNavigate();

	useEffect(() => {
		if(window.location.host === "www.berichts-navi.de" || window.location.host === "berichts-navi.de") {
			alert("Liebe Kolleg*innen, auch wenn das nachfolgende Programm \"Psychodynamik-Navi\" heißt, können Sie dieses auch als Hilfe für das Berichteschreiben in allen (!) 4 Psychotherapieverfahren nutzen: also nicht nur für die Tiefenpsychologisch fundierte Psychotherapie (TP); sondern auch für die analytische Psychotherapie (AP); für die Verhaltenstherapie (VT) und für die Systemische Psychotherapie (ST). Wenn Sie also einen AP/VT/ST-Bericht schreiben wollen, klicken Sie im Programm, wenn Sie bei Punkt 4 angelangt sind, einfach auf das Feld \"Frei schreiben\". Dann startet ein Textfeld, mit dem Sie Punkt 4 in freier Form (d. h. ohne Leitfaden) schreiben können (z.B. für Ihre Verhaltensanalyse oder Ihre Systemanalyse). Desweiteren verweise ich die FAQ-Liste. Viel Erfolg. Ingo Jungclaussen.");
		}
	});

	setTimeout(() => {
		setAnimate(false);
		localStorage.setItem("showAnimation", "false");
	}, 5000);

	return (
		<>
			<Container className={`${styles.homeContainer} mb-5`}>
				{!animate && 
				<div>
					<h1 className="text-center mb-3">Psychodynamik-Navi</h1>
					<h4 className="text-center mb-3">Die neue digitale Schreib-Unterstützung für Ihren Antragsbericht</h4>
					<h4 className="text-center mb-5">
						Auch die längste Reise beginnt mit dem ersten Schritt - Kommen Sie mit an Bord und fahren Sie dem Aufschieben davon!
					</h4>
					<div>
						<p className="text-center mt-4">
							<Button variant="primary" size="lg" onClick={() => navigate("/report")}>Hier starten</Button>
						</p>

						<iframe className="d-block m-auto" width="560" height="315" src="https://www.youtube.com/embed/1A2pXZ-8gGA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
						
						<p className="text-center mt-4">
							Herzlich Willkommen in Ihrem Psychodynamik-Navi!
							<br /><br />
							<Button variant="primary" size="lg" onClick={() => navigate("/report")}>Hier starten</Button>
						</p>
						<p>
							Geht es Ihnen auch oft so, dass Sie sich beim Gedanken den Antragsbericht verfassen zu müssen, wie orientierungslos in einem Meer an Möglichkeiten verlieren – vor einer Aufgabe stehend, die Sie immer weiter vor sich herschieben, wodurch das Ziel immer weiter in die Ferne rückt? Das "Psychodynamik-Navi" ist eine neuartige Software, die Ihnen in genau dieser Situation hilft.
						</p>
						<p>
							Die Software führt Sie Schritt für Schritt durch die 7 Gliederungspunkte des z.B. Umwandlungs-Berichts oder Erstantrag-Berichts (LZT). Das Programm ist bei Punkt 4 auf die TP spezialisiert. Es kann aber auch für alle Therapieverfahren genutzt werden (AP, VT, ST). Genaueres hierzu s. im Programm oder FAQ. Jeden einzelnen Schritt beantworten Sie selbst über ein Eingabefeld mit Ihren eigenen Texten. Abschließend klicken Sie bequem auf "Bericht generieren". Die Software fügt dann Ihre Text-Eingaben automatisch in einer Word-Datei zusammen, wobei diese gleich an der richtigen Stelle der PTV 3- Gliederungsstruktur landen. Abschließend können Sie Ihren Bericht prüfen und ggf. bequem an Ihrem Computer vervollständigen. Ihr Weg ans Ziel bleibt der Gleiche. Doch dank der "Portionierung" Ihres Schreibprozesses haben Sie von Anfang an den Wind in den Segeln. Sie erfahren eine Erleichterung und Strukturierung Ihrer gedanklichen und schriftlichen Antragsarbeit und werden so ohne Umwege ans Ziel navigiert. Hierdurch können Sie Ihren Bericht ein Vielfaches schneller verfassen, als sonst.
						</p>
						<p>
							Das "Psychodynamik-Navi" folgt dabei der gleichen Didaktik-Philosophie wie Sie diese aus meinen <a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">Fortbildungen und Handbücher zur Psychodynamik</a> kennen. Hier steht vor allem in der <a href="https://psy-dak.de/artikel/neue-ansaetze-in-der-didaktik/" target="_blank" rel="noreferrer">Didaktik der Psychodynamischen Psychotherapie</a> die Methode der Strukturierung und Komplexitäts-Reduktion im Fokus. Gleichzeitig folgt das Programm den Anforderungen in der Berichtserstellung, wie diese in den Psychotherapie-Richtlinien sowie im <a target="_blank" rel="noreferrer" href="https://psy-dak.de/ueber-den-faber-haarstrick-kommentar/">Faber/Haarstrick-Kommentar</a> beschrieben werden. (Dem Vernehmen nach auf berufspolitischer Ebene wird uns das Antrags- und Gutachterverfahren noch länger erhalten bleiben (also über Ende 2022 hinaus). Wenn Sie auch hierüber regelmäßig informiert werden möchten, tragen Sie sich in den kostenlosen <a href="https://psy-dak.de/newsletter/" target="_blank" rel="noreferrer">Newsletter</a> ein.)
						</p>
						<div className="d-block m-auto w-50 text-center mb-3">
							<img src={Images.vor_karte} alt="Start-Bild" className="img-fluid clickable" onClick={handleShow} />
							<span className="fs-6">Zum Bild-größer-machen aufs Bild klicken</span>
						</div>
						<p>
							Begleitet werden Sie durchs Programm durch die bekannten "Drillinge Ich, Es und Überich" von Hans Biedermann. Informieren Sie sich über die obigen Menüpunkte, auch zum <Link to="/privacy">Datenschutz</Link> sowie zu weiteren <Link to="/upgrades">Upgrade-Möglichkeiten</Link>. Die Drillinge und ich wünschen Ihnen volle Fahrt voraus und immer eine Handbreit Wasser unterm Kiel. Um zu Starten klicken Sie <Link to="/report">hier</Link>.
						</p>
						<img src="/portrait.jpg" width={200} className="d-block m-auto" alt="Portrait" />
						<p>
							Mit freundlichen Grüßen<br />
							Ihr Prof. Dr. Ingo Jungclaussen
						</p>
						<p className="text-center">
							<Button variant="primary" size="lg" onClick={() => navigate("/report")}>Hier starten</Button>
						</p>
					</div>
				</div>
}
				{animate &&
					<div className={styles.imageContainer}>
						<img src={Images.segelbild} alt="Segelbild" />
					</div>
				}
			</Container>

			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src={Images.vor_karte} alt="Start-Bild" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}