import { Container } from "react-bootstrap";

export default function Imprint() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Impressum</h1>
					<p>
					Psy-Dak<br/>
					Institut für Psychodynamische Didaktik<br/>
					Prof. Dr. Dipl.-Psych. Ingo Jungclaussen<br/>
					Goldlackstrasse 14<br/>
					40627 Düsseldorf<br/>
					E-Mail: info@psy-dak.de
					</p>
				</div>
			</Container>
		</>
	);
}