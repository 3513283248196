import { Container, Nav, Navbar } from "react-bootstrap";

export default function NavigationBar() {
	return (
		<Navbar bg="light" expand="xl" sticky="top">
			<Container fluid>
				<Navbar.Brand href="/">
					<img src="/psy-dak-logo.png" height={60} alt="Logo" className="me-2 d-inline-block align-text-center" />
					<span className="fs-4">Psychodynamik-Navi</span>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link href="/">Home</Nav.Link>
						<Nav.Link href="/report" className="text-primary fw-bold">Bericht schreiben</Nav.Link>
						<Nav.Link href="/about">Über mich</Nav.Link>
						<Nav.Link href="/seminare">Seminare/Buch</Nav.Link>
						<Nav.Link href="/upgrades">Upgrades</Nav.Link>
						<Nav.Link href="/training">Fortbildungspunkte</Nav.Link>
						<Nav.Link href="/newsletter">Newsletter</Nav.Link>
						<Nav.Link href="/feedback" >Feedback</Nav.Link>
						<Nav.Link href="/faq">FAQ</Nav.Link>
						<Nav.Link href="/privacy">Datenschutz</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}