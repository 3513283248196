import { Container } from "react-bootstrap";

export default function Partner() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Kooperationspartner</h1>
					<ul>
						<li>Cartoonist und Psychologe: Hans Biedermann (Hennes) <a href="https://hennes-cartoon.de/" target="_blank" rel="noreferrer">https://hennes-cartoon.de/</a></li>
						<li>Akademie für Psychotherapie Pforzheim Dr. med. Polster <a href="https://afpp.de/" target="_blank" rel="noreferrer">https://afpp.de/</a></li>
						<li>Grafikerin und Psychotherapeutin i. A. Dipl.-Psych. Elke Scheurich (<a href="https://www.basisrose.de" target="_blank" rel="noreferrer">www.basisrose.de</a>)</li>
						<li>Weitere folgen </li>
					</ul>
				</div>
			</Container>
		</>
	);
}