import { Document, Packer, Paragraph, TextRun } from "docx";
import { isChecklistItemImage } from "../models/ChecklistItemImage";
import ChecklistItemTask, { isChecklistItemTask } from "../models/ChecklistItemTask";
import ChecklistPage from "../models/ChecklistPage";

export default class ExportService {
	public generateDocx(checklistPages: ChecklistPage[]): Promise<Blob> {
		const section = {
			children: [] as Paragraph[]
		};

		section.children.push(new Paragraph({
			children: [
				new TextRun({
					text: "Bericht zum Antrag auf Umwandlung von KZT in LZT",
					font: "Cambria",
					size: 24,
					bold: true
				})
			]
		}));
		section.children.push(new Paragraph({
			children: [
				new TextRun({
					text: "Chiffre:",
					font: "Cambria",
					size: 24,
					bold: true
				})
			]
		}));

		let nextPage = undefined;

		for (let page of checklistPages) {
			if(nextPage !== undefined && nextPage !== page.key) {
				continue;	
			}

			nextPage = page.nextPage;

			// we have a pending choice --> skip all choice pages
			if(nextPage && nextPage.startsWith("{{")) {
				nextPage = nextPage.substring(2, nextPage.length - 2);
			}

			if(page.includeTitleInReport) {
				section.children.push(new Paragraph({}));
				const heading = new Paragraph({
					children: [
						new TextRun({
							text: page.title,
							font: "Cambria",
							size: 24,
							bold: true
						})
					]
				});

				section.children.push(heading);
			}

			for (let checklistItem of page.items) {
				if (!isChecklistItemImage(checklistItem)) {
					for (let item of checklistItem.items) {
						if (isChecklistItemTask(item)) {
							const task = item as ChecklistItemTask;

							if(task.textInput) {
								for (let textInput of task.textInput.split("\n")) {
									section.children.push(new Paragraph({
										children: [
											new TextRun({
												text: textInput,
												font: "Cambria",
												size: 24
											})
										]
									}));
								}
							}
						}
					}
				}
			}
		}

		const doc = new Document({
			sections: [section]
		});

		return Packer.toBlob(doc);
	}
}