import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import AppPage from "./components/appPage/AppPage";
import Home from "./components/home/Home";
import Layout from "./components/layout/Layout";
import NavigationBar from "./components/navbar/Navbar";
import AppFinish from "./components/appFinish/AppFinish";
import AppDone from "./components/appDone/AppDone";
import Upgrades from "./components/upgrades/Upgrades";
import Newsletter from "./components/newsletter/Newsletter";
import Seminar from "./components/seminar/Seminar";
import Partner from "./components/partner/Partner";
import Imprint from "./components/imprint/Imprint";
import Training from "./components/training/Training";
import Report from "./components/report/Report";
import Privacy from "./components/privacy/Privacy";
import About from "./components/about/About";
import FeedbackSurvey from "./components/feedbackSurvey/FeedbackSurvey";
import Feedback from "./components/feedback/Feedback";
import AGB from "./components/agb/AGB";
import AppPreStart from "./components/appPreStart/AppPreStart";
import FAQ from "./components/faq/FAQ";

import "./styles/bootstrap.custom.scss";
import "bootstrap-icons/font/bootstrap-icons.css";
import "github-markdown-css/github-markdown.css";
import AppLicense from "./components/appLicense/AppLicense";

export default function App() {
  return (
    <BrowserRouter>
      <NavigationBar />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/report" element={<Report />} />
          <Route path="/about" element={<About />} />
          <Route path="/seminare" element={<Seminar />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/upgrades" element={<Upgrades />} />
          <Route path="/training" element={<Training />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/feedbackSurvey" element={<FeedbackSurvey />} />
          <Route path="/partner" element={<Partner />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/agb" element={<AGB />} />
          <Route path="/app/license" element={<AppLicense />} />
          <Route path="/app/pre_start" element={<AppPreStart />} />
          <Route path="/app/finished" element={<AppFinish />} />
          <Route path="/app/done" element={<AppDone />} />
          <Route path="/app/page/:pageKey">
            <Route path=":itemKey" element={<AppPage />} />
            <Route path="" element={<AppPage />} />
          </Route>
          <Route path="/faq" element={<FAQ />} />
        </Routes>
      </Layout>
      <Navbar fixed="bottom" bg="light" className="d-block text-center">
        <span className="me-3">&copy; {moment().year()} Psy-Dak</span>
        <Link className="me-3" to="/imprint">Impressum</Link>
        <Link className="me-3" to="/agb">AGB</Link>
        <Link className="me-3" to="/partner">Koop.-Partner</Link>
      </Navbar>
    </BrowserRouter>
  );
}
