import { FormEvent, useState } from "react";
import { Container, Form, Button, Alert, FormCheck, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ChecklistService, { LICENSE_KEY } from "../../services/ChecklistService";
import { Images } from "../../img/Images";

import styles from "./AppLicense.module.scss";

export default function AppLicense() {
	const [license, setLicense] = useState(localStorage.getItem(LICENSE_KEY) || "");
	const [error, setError] = useState("");
	const [agb, setAgb] = useState(false);
	const [privacy, setPrivacy] = useState(false);
	const [showImage, setShowImage] = useState(false);
	const navigate = useNavigate();
	const checklistService = new ChecklistService();

	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);

	const handleSubmit = async (event: FormEvent) => {
		event.preventDefault();
		event.stopPropagation();

		const result = await checklistService.checkLicense(license)

		if (result === "") {
			localStorage.setItem(LICENSE_KEY, license);
			navigate("/app/pre_start");
		} else {
			setError(result);
		}
	};

	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-5">Lizenz-Schlüssel eingeben</h1>
					<div className={`${styles.imageContainer} d-block m-auto text-center mb-5`}>
						<img src={Images.vor_karte} alt="Start-Bild" className="img-fluid clickable" onClick={handleShow} />
						<span className="fs-6 d-block">Zum Bild-größer-machen aufs Bild klicken</span>
					</div>
					<p>
						Geben Sie hier Ihren Lizenz-Schlüssel mit Bindestrichen ein:
					</p>
					<p>
						Hier können Sie einen Lizenz-Schlüssel erwerben: <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a>. (Antworten auf mögliche Fragen bekommen Sie hier: <Link to="/faq">FAQ</Link>)
					</p>
					<Form onSubmit={handleSubmit}>
						<Form.Control
							type="text"
							placeholder="XXXX-XXXX-XXXX-XXXX"
							defaultValue={license}
							onChange={(ev) => setLicense(ev.target.value)}
							required
						/>
						{error !== "" &&
							<Alert variant="danger" className="mt-2">
								{error}
							</Alert>
						}
						<Form.Check className="mt-3">
							<FormCheck.Input type="checkbox" checked={agb} onChange={e => setAgb(e.target.checked)} />
							<FormCheck.Label className="ms-2">Ich habe die <Link to="/agb">Allgemeinen Geschäftsbedingungen</Link> gelesen</FormCheck.Label>
						</Form.Check>
						<Form.Check>
							<FormCheck.Input type="checkbox" checked={privacy} onChange={e => setPrivacy(e.target.checked)} />
							<FormCheck.Label className="ms-2">Ich habe die <Link to="/privacy">Datenschutzerklärung</Link> zur Kenntnis genommen</FormCheck.Label>
						</Form.Check>
						<Button disabled={!agb || !privacy} className="mt-3" type="submit">Speichern</Button>
					</Form>
				</div>
			</Container>
			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src={Images.vor_karte} alt="Start-Bild" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}