export const LICENSE_KEY = "license";
export const VERSION_KEY = "version";

export default class ChecklistService {

	public async loadChecklist(): Promise<any> {
		const version = localStorage.getItem(VERSION_KEY) || "latest";
		const license = localStorage.getItem(LICENSE_KEY);

		if (license) {
			const headers = new Headers();
			headers.append("X-License", license);

			try {
				const response = await fetch(`/api/checklist/${version}`, { headers });

				if (response.status === 200) {
					const json = await response.json();

					const respVersion = response.headers.get("X-Version");
					localStorage.setItem(VERSION_KEY, respVersion || "latest");

					return json;
				} else {
					if(response.status !== 500) {
						window.location.href = "/app/license";
					} else {
						const body = await response.text();
						alert(body);
					}
				}
			} catch (ex) {
				console.error(ex)
			}
		} else {
			window.location.href = "/app/license";
		}
	}

	public async checkLicense(license: string): Promise<string> {
		const headers = new Headers();
		headers.append("Content-Type", "application/json");

		const payload = {license};

		const response = await fetch(`/api/licenseCheck`, { method: "POST", headers, body: JSON.stringify(payload)});

		if (response.status === 200) {
			return ""
		} else {
			const body = await response.json();
			return body.message
		}
	}
}