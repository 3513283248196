import { useState } from "react";
import { Container, Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Images } from "../../img/Images";

import styles from "./Report.module.scss";

export default function Report() {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);
	const navigate = useNavigate();

	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Bericht schreiben</h1>
					<p>
						Sie möchten Ihren Bericht mit dem Programm als Hilfsmittel erstellen?
					</p>
					<div className="text-center my-3">
						<Button variant="primary" size="lg" onClick={() => navigate("/app/license")}>Dann klicken Sie hier</Button>
					</div>
					<p>
						Das Programm ist selbsterklärend: Sie werden Seite für Seite durch die Gliederungsstruktur des Antragsberichts (gemäß PTV-3) geführt und tragen eigenständig Ihre Überlegungen zu Ihrer Patientin oder Ihrem Patienten ein.
						<br />
						Die Hilfe besteht in der Strukturierung und Portionierung Ihres Schreib- und Denkprozesses.
						<br />
						Der scheinbar große Berg Arbeit wird in überschaubare Schritte portioniert. Die Arbeit verliert darüber hinaus an Schwere, indem Sie durch den ganzen Bericht von den Drillingen begleitet werden – den bekannten Kartoffelmännchen, dem <i>Ich</i>, <i>Es</i> und <i>Überich</i> von <a href="https://hennes-cartoon.de/" target="_blank" rel="noreferrer">Hans Biedermann</a>. Sie begleiten die Drillinge bei einer spannenden Seefahrts- und Schatz-Geschichte. Das Programm ist auf TP spezialisiert, kann aber auch für alle anderen Verfahren genutzt werden. (Details im Programm oder <Link to="/faq">FAQ</Link>)
					</p>
					<div className="d-block m-auto w-50 text-center mb-3">
						<img src={Images.schiff} alt="Schiff" className="img-fluid clickable" onClick={handleShow} />
						<span className="fs-6">Zum Bild-größer-machen aufs Bild klicken</span>
					</div>
					<p className="text-center">
						Sie möchten starten?
					</p>
					<div className="text-center my-3">
						<Button variant="primary" size="lg" onClick={() => navigate("/app/license")}>Dann klicken Sie hier</Button>
					</div>
				</div>
			</Container>
			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src={Images.schiff} alt="Schiff" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}