import segelbild from "./img_data/segelbild.jpg";

import ausguck from "./img_data/Ausguck.jpg";
import boot from "./img_data/Boot.jpg";
import fin from "./img_data/Fin.jpg";
import fleiss_preis from "./img_data/Fleiss-Preis.jpg";
import kajuete from "./img_data/Kajuete.jpg";
import klarschiff from "./img_data/Klarschiff.jpg";
import leuchtturm_behandlungsplan from "./img_data/Leuchtturm_Behandlungsplan.jpg";
import leuchtturm_diagnose from "./img_data/Leuchtturm_Diagnose.jpg";
import leuchtturm_psychodynamik from "./img_data/Leuchtturm_Psychodynamik.jpg";
import leuchtturm_som_befund from "./img_data/Leuchtturm_Som_Befund.jpg";
import leuchtturm_soziodem from "./img_data/Leuchtturm_Soziodem.jpg";
import leuchtturm_symptomatik from "./img_data/Leuchtturm_Symptomatik.jpg";
import leuchtturm_umwandlungsbericht from "./img_data/Leuchtturm_Umwandlungsbericht.jpg";
import pause from "./img_data/Pause.jpg";
import schatztruhe from "./img_data/Schatztruhe.jpg";
import schiff from "./img_data/Schiff.jpg";
import sturm from "./img_data/Sturm.jpg";
import vor_karte from "./img_data/vor-Karte.jpg";
import weg_01 from "./img_data/Weg_01.jpg";
import weg_02 from "./img_data/Weg_02.jpg";
import weg_03 from "./img_data/Weg_03.jpg";
import weg_04 from "./img_data/Weg_04.jpg";
import weg_05 from "./img_data/Weg_05.jpg";
import weg_06 from "./img_data/Weg_06.jpg";
import weg_07 from "./img_data/Weg_07.jpg";

export const Images = {
	segelbild: segelbild,

	ausguck: ausguck,
	boot: boot,
	fin: fin,
	fleiss_preis: fleiss_preis,
	kajuete: kajuete,
	klarschiff: klarschiff,
	leuchtturm_01: leuchtturm_soziodem,
	leuchtturm_02: leuchtturm_symptomatik,
	leuchtturm_03: leuchtturm_som_befund,
	leuchtturm_04: leuchtturm_psychodynamik,
	leuchtturm_05: leuchtturm_diagnose,
	leuchtturm_06: leuchtturm_behandlungsplan,
	leuchtturm_07: leuchtturm_umwandlungsbericht,
	pause: pause,
	schatztruhe: schatztruhe,
	schiff: schiff,
	sturm: sturm,
	vor_karte: vor_karte,
	weg_01: weg_01,
	weg_02: weg_02,
	weg_03: weg_03,
	weg_04: weg_04,
	weg_05: weg_05,
	weg_06: weg_06,
	weg_07: weg_07
};

export type ImageKeys = keyof (typeof Images);