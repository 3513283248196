import { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Images } from "../../img/Images";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { clear, populate, selectPages } from "../../redux/pages/pagesSlice";
import ChecklistService from "../../services/ChecklistService";
import ExportService from "../../services/ExportService";

import styles from "./AppFinish.module.scss";

export default function AppFinish() {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);
	const pages = useAppSelector(selectPages);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const checklistService = new ChecklistService();
		checklistService.loadChecklist()
			.then((checklistData: any) => {
				dispatch(populate(checklistData));
			});
	}, [dispatch]);

	const generate = () => {
		const service = new ExportService();
		service.generateDocx(pages).then(blob => {
			var link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			var fileName = "Bericht.docx";
			link.download = fileName;
			link.click();

			const checklistService = new ChecklistService();
			checklistService.loadChecklist()
				.then((checklistData: any) => {
					dispatch(clear(checklistData));

					setTimeout(() => {
						navigate("/app/done");
					}, 3000);
				});
		});
	}

	return (
		<>
			<Container className="d-flex flex-grow-1 flex-column justify-content-center">
				<div className={`${styles.imageContainer} d-block m-auto text-center mb-3`}>
					<img src={Images.schatztruhe} alt="Ziel-Bild" className="img-fluid clickable" onClick={handleShow} />
					<span className="fs-6 d-block">Zum Bild-größer-machen aufs Bild klicken</span>
				</div>
				<div className="text-center my-3">
					<Button variant="primary" size="lg" onClick={() => generate()}>Bericht generieren</Button>
				</div>
				<p className="text-center">
					<b>Ihren mit Ihren Texten erstellten Bericht finden Sie auf Ihrem Computer in Ihrem Download-Ordner</b>
				</p>
			</Container>
			<Modal show={showImage} onHide={handleClose} centered size="xl">
				<Modal.Header closeButton />
				<Modal.Body>
					<div className={`${styles.modalContainer}`}>
						<img src={Images.schatztruhe} alt="Ziel-Bild" />
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}