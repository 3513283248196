import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Form, ProgressBar, Row } from "react-bootstrap"
import { saveAs } from "file-saver";
import ChecklistItem from "../../models/ChecklistItem";
import ChecklistPage from "../../models/ChecklistPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { init, selectPages, selectPagesState, setShowImagePages, setState, showImagePages } from "../../redux/pages/pagesSlice";
import React from "react";
import ChecklistService, { VERSION_KEY } from "../../services/ChecklistService";

export interface AppSidebarProps {
	page: ChecklistPage;
	item: ChecklistItem;
}

export default function AppSidebar(props: AppSidebarProps) {
	const pages = useAppSelector(selectPages);
	const showImages = useAppSelector(showImagePages);
	const pagesState = useAppSelector(selectPagesState);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const curIdx = pages.findIndex(p => p.key === props.page.key);
	const percent = Math.round(pages.length > 0 ? (curIdx / pages.length) * 100 : 0);

	const onShowImagesChange = () => {
		dispatch(setShowImagePages(!showImages));
	}

	const onClearInputs = () => {
		const checklistService = new ChecklistService();
		checklistService.loadChecklist()
			.then((checklistData: any) => {
				dispatch(init(checklistData));
				navigate("/report");
			});
	}

	const onExport = () => {
		const exportObject = {
			state: pagesState,
			version: localStorage.getItem(VERSION_KEY)
		}
		const blob = new Blob([JSON.stringify(exportObject)], { type: "application/json" });
		saveAs(blob, "Bericht.json");
	}

	const onImport = () => {
		const fileInput = document.getElementById("importFile");

		if (fileInput) {
			fileInput.click();
		}
	}

	const onFileSelected = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (ev.target.files && ev.target.files.length > 0) {
			const file = ev.target.files[0];

			const fileReader = new FileReader();
			fileReader.onload = () => {
				if (fileReader.result) {
					const fileContent = fileReader.result as string;
					dispatch(setState(fileContent));
					navigate("/app/page/page_1");
				}
			}

			fileReader.readAsText(file);
		}
	}

	return (
		<div className="overflow-auto">
			<div className="d-none d-lg-block">
				{pages.map((page, idx) => {
					if (page.includeTitleInReport) {
						return (
							<div key={page.key} className="d-flex">
								<span className="me-2 flex-shrink">
									{page.done ?
										<i className="bi bi-check-circle-fill text-success"></i>
										:
										<i className="bi bi-circle"></i>
									}
								</span>
								<Link to={`/app/page/${page.key}`} className="text-decoration-none text-reset">
									{page.key === props.page.key ?
										<b>{page.title}</b>
										:
										<span>{page.title}</span>
									}
								</Link>
							</div>
						);
					} else {
						return (null);
					}
				})}
			</div>
			<ProgressBar now={percent} label={`${percent}%`} variant="primary" className="mt-4 mx-1" />
			<div className="mt-3 mx-1">
				<Form.Check
					type="checkbox"
					id="show_images"
					label="Cartoon-Bilder anzeigen"
					checked={showImages}
					onChange={onShowImagesChange}
				/>
			</div>
			<Row className="mx-0 mb-5">
				<Col xxl={6} lg={12} sm={6} className="mt-3">
					<Link className="btn btn-sm btn-primary w-100" to="/app/finished">Bericht vorzeitig abschließen</Link>
				</Col>
				<Col xxl={6} lg={12} sm={6} className="mt-3">
					<Button className="btn btn-sm btn-primary w-100" onClick={onClearInputs}>Alle Daten löschen</Button>
				</Col>
				<Col xxl={6} lg={12} sm={6} className="mt-3">
					<Button className="btn btn-sm btn-primary w-100" onClick={onExport}>Export</Button>
				</Col>
				<Col xxl={6} lg={12} sm={6} className="mt-3">
					<Button className="btn btn-sm btn-primary w-100" onClick={onImport}>Import</Button>
					<input type="file" id="importFile" accept="application/json" className="d-none" onChange={onFileSelected} />
				</Col>
			</Row>
		</div>
	);
}