import { Container } from "react-bootstrap";

export default function Seminar() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Seminar und Buch als optimale Vertiefungsmöglichkeit zu diesem Programm "Psychodynamik-Navi"</h1>
					<p>
						Das vorliegende Programm portioniert und strukturiert die Berichts-Anforderungen in einer bestimmten Weise. Dabei werden besonders im Kontext der Psychodynamik bestimmte gängige Konzepte, Begriffe und Modelle aus dem Bereich der Psychodynamischen Psychotherapie verwendet (wie z.B. der Begriff des Grundkonflikts, Neurosenstrukturen, Aktuell wirksamer unbewusster Konflikt oder auch der strukturellen Störung, uvm.).
					</p>
					<p>
						Ebenso wird auf die Berichts-Anforderungen im Rahmen der Psychotherapie-Richtlinien und des jeweils neusten Faber/Haarstrick-Kommentars Bezug genommen
					</p>
					<p>
						Wenn Sie Ihre Kenntnisse zu diesen Fach-Begriffen, Theorien, Modellen und Richtlinien vertiefen möchten, empfehle ich Ihnen folgende Fortbildungs- und Lektüre- Möglichkeiten
					</p>
					<ol>
						<li>Die zertifizierten Fortbildungs-Seminare meines Angebots unter Psy-Dak (<a href="https://www.psy-dak.de" target="_blank" rel="noreferrer">www.psy-dak.de</a>)</li>
						<img className="mb-3 pt-3" src="/psy_dak_logo_circle.png" alt="Logo" />
						<li>Die Lektüre meines Handbuch Psychotherapie im Schattauer Verlag (<a href="https://psy-dak.de/das-buch-zum-seminar/neuauflage-handbuch-2018/" target="_blank" rel="noreferrer">Infos hier</a>)</li>
						<img className="mb-3 pt-3" width={300} src="/book.jpeg" alt="Buch" />
					</ol>
					<p>
						All diese Angebote (das Programm Psychodynamik-Navi, das Seminar, das Handbuch) beziehen sich auf die gleichen Begriffe und Modelle. 
					</p>
					<p>
						Sie können sich also durch die Lektüre des Handbuches oder durch den Besuch des Seminars in stringenter Weise so weiterbilden, dass Sie die Begriffe und Modelle im Rahmen dieses Programms "Psychodynamik-Navi" optimal beherrschen.
					</p>
					<p>
						Seminar, Buch und Programm sind kohärent zueinander und verwenden die gleiche Logik sowie die gleichen Begriffe. 
					</p>
					<ol start={3}>
						<li>
							Frag- Freud! - Das im Aufbau befindliche E-Learning-Angebot zur Psychodynamischen Psychotherapie:<br/>
							Wenn Sie hierüber mehr erfahren möchten klicken Sie die eigene <a href="https://frag-freud.de/" target="_blank" rel="noreferrer">Webseite</a> an und tragen sich in den kostenlosen <a href="https://frag-freud.de/anmeldung-newsletter/" target="_blank" rel="noreferrer">Newsletter</a> ein.
						</li>
						<img className="mb-3 pt-3" width={200} src="/frag_freud.jpg" alt="Frag Freud" />
					</ol>
				</div>
			</Container>
		</>
	);
}