import DOMPurify from "dompurify";
import { Button } from "react-bootstrap";
import ChecklistItem from "../../models/ChecklistItem";
import ChecklistItemChoice, { ChecklistItemChoiceOption } from "../../models/ChecklistItemChoice";
import ChecklistPage from "../../models/ChecklistPage";
import { useAppDispatch } from "../../redux/hooks";
import { setChoicePages } from "../../redux/pages/pagesSlice";

export interface AppItemChoiceProps {
	page: ChecklistPage;
	item: ChecklistItem;
	choiceItem: ChecklistItemChoice;
}

export default function AppItemChoice(props: AppItemChoiceProps) {
	const dispatch = useAppDispatch();

	const onSelect = (option: ChecklistItemChoiceOption) => {
		dispatch(setChoicePages(option));
	}

	return (
		<>
			{props.choiceItem.annotation_pre && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.choiceItem.annotation_pre) }}></p>
			)}

			{props.choiceItem.annotation_in && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.choiceItem.annotation_in) }}></p>
			)}

			{props.choiceItem.options.map((optionItem, idx) => {
				return (
					<p key={optionItem.key}>
						<Button variant="primary" className="me-3" onClick={() => onSelect(optionItem)}>{optionItem.option}</Button>
						{optionItem.text}
					</p>
				);
			})}

			{props.choiceItem.annotation_post && (
				<p className="mt-3" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.choiceItem.annotation_post) }}></p>
			)}
		</>
	);
}