import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AppPreStart() {
	return (
		<>
			<Container className="d-flex flex-grow-1 flex-column justify-content-center">
				<div>
					<div className="text-end my-3">
						<Link className="btn btn-primary btn-lg" to="/app/page/page_1">Weiter</Link>
					</div>
					<p>Bevor es auf der nächsten Seite los geht, für Sie ein paar nützliche Hinweise für Ihre Anwendung:</p>
					<ul>
						<li><b>Einfach hin- und herspringen:</b> Sie können ab der nächsten Seite innerhalb der nachfolgenden Schritte so oft hin- und herspringen, wie Sie wollen, es werden hierdurch Ihre Texte nicht gelöscht. Das hat den Vorteil, dass Sie Ihren Bericht auch nach einer Pause weiterschreiben können, solange Ihr Browserfenster geöffnet ist.</li>
						<li><b>Export-Import-Funktion:</b> Wenn Sie eine längere Pause wünschen, z.B. wenn Sie Ihren hier angefangenen Bericht abspeichern und an einem anderen Tag weiterschreiben wollen, nutzen Sie hierfür die Exportfunktion. Diese finden Sie direkt im Programm. Zur Orientierung: Diese Export-Datei lautet "Bericht.json" und wird nur (!) auf Ihrem Rechner gespeichert. Wenn Sie an Ihrem Bericht im Programm weiterschreiben wollen, klicken Sie im Programm auf "Import" und wählen diese Datei "Bericht.json" aus. Dann wird der bisherige Textstand ins Programm automatisch eingespielt und Sie können Ihren Bericht vervollständigen.</li>
						<li><b>Datenschutz:</b> Nochmal der Hinweis: Alle Ihre Eintragungen ins Programm werden nur lokal in Ihrem Browser gespeichert, d.h. alle Ihre Eintragungen verlassen zu keinem Zeitpunkt Ihren Rechner. Lesen hierzu auch die <a target="_blank" rel="noreferrer" href="/privacy">Datenschutz-Informationen</a>.</li>
						<li><b>Logout/Löschen:</b> Ihre Texte werden auf zweierlei Weise gelöscht: 1) Wenn Sie auf den Button "Alle Daten löschen" klicken und 2) wenn Sie am Ende auf "Bericht generieren" klicken und Sie Ihren Bericht in Ihrem Download-Ordner erhalten. Dieser Schritt (ein Bericht wird erstellt) entspricht dann quasi auch einem "Logout" in diesem Programm und alle lokalen Eintragungen in Ihrem Browser werden hierbei gelöscht.</li>
						<li><b>Word-Datei am Ende:</b> Diese erstellte Word-Datei am Ende unterscheidet sich in keiner Weise von einer normalen Word-Datei. Diese Datei können Sie nach Belieben verändern, kürzen, erweitern und dann abspeichern auf Ihrem Computer.</li>
						<li><b>Tipp:</b> Halten Sie Ihre Texte in den nachfolgenden Textfeldern möglichst prägnant. Ansonsten kann Ihr Bericht schnell sehr lang werden (den Sie dann in der Word-Datei ggf. wieder kürzen müssen)</li>
						<li><b>Musterfall:</b> Einen vollständigen Musterfall, wie ein fertiger Bericht an den Gutachter aussieht, finden Sie in meinem <a target="_blank" rel="noreferrer" href="https://psy-dak.de/das-buch-zum-seminar/neuauflage-handbuch-2018/">Handbuch Psychotherapieantrag</a> ab der Seite 481 (Kap. 20). Hierüber bekommen Sie einen Eindruck über den richtigen Grad an Ausführlichkeit und auch ein Beispiel über die mögliche Art des Ausdrucks.</li>
						<li><b>Nur Psychodynamik schreiben:</b> Sie können auf der nächsten Seite (links im Inhaltsverzeichnis) auf Wunsch auch per Mausklick direkt auf Punkt 4 (Psychodynamik) springen, wenn Sie nur diesen Punkt mit dem Programm schreiben möchten.</li>
						<li><b>Bericht vor dem Ende vorzeitig abschließen:</b> Sie können im Programm jederzeit auf "Bericht vorzeitig abschließen" klicken: Dann erhalten Sie Ihren Bericht als Word-Datei generiert, genau zu dem jeweiligen Bearbeitungs-Stand. (Das bietet sich z.B. an, wenn Sie nur die Psychodynamik mit dem Programm schreiben möchten, siehe obiger Punkt)</li>
						<li><b>Psychodynamik ohne Leitfaden schreiben:</b> Das Programm ist bei Punkt 4 (Psychodynamik) auf die TP spezialisiert, d.h. Sie bekommen bei Punkt 4 mehrere TP-Psychodynamik-Leitfäden zur Auswahl (Konflikt-, Struktur- oder Traumastörung). Wenn Sie die TP-Psychodynamik aber auch ohne Leitfaden – quasi frei- schreiben möchten, können Sie dort auch die Funktion wählen "ohne Leitfaden schreiben". Dann schreiben Sie Punkt 4 in einem (!) Textfeld völlig frei.</li>
						<li><b>Alle Verfahren möglich:</b> Die o.g. Funktion (Punkt 4 freischreiben/ohne Leitfaden) erlaubt es Ihnen darüber hinaus auch, das Programm nicht nur für einen TP-Bericht, sondern auch für einen Bericht in der analytischen Psychotherapie, Verhaltenstherapie oder Systemischen Psychotherapie zu nutzen.</li>
						<li><b>Smartphone/Tablet:</b> Das Programm Psychodynamik-Navi können Sie auch auf dem Smartphone oder Tablet anwenden.</li>
						<li><b>Technischer Hinweis:</b> Einige Anwender*innen hatten in einer älteren Version des Leitfadens, bei der es einen kl. Fehler gab, das Problem, dass die Navigation am Ende in einem Kreis verlief. Wenn Sie dieses Problem hatten, können Sie unter <a target="_blank" rel="noreferrer" href="/app/finished">diesem Link</a> den Bericht noch nachträglich generieren und abschließen. In der neuen Version, in der das Problem behoben wurde und bei nachfolgenden Berichten tritt dieses Problem nicht mehr auf. (Ansonsten ignorieren Sie diesen Hinweis)</li>
						<li><b>FAQs:</b> Antworten auf häufig gestellte weitere Fragen finden Sie <a target="_blank" rel="noreferrer" href="/faq">hier</a></li>
					</ul>
					<p>Viel Freude mit dem Berichtschreiben. Los geht's!</p>
				</div>
				<div className="text-end my-3">
					<Link className="btn btn-primary btn-lg" to="/app/page/page_1">Weiter</Link>
				</div>
			</Container>
		</>
	);
}