import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function FAQ() {
	return (
		<Container className="mb-5">
			<div>
				<h1 className="text-center mb-3">FAQ</h1>
				<p>Hier erhalten Sie Antworten auf häufig gestellte Fragen zum Programm und seiner Nutzung:</p>
				<Card className="mb-5">
					<Card.Body>
						<p>Inhalt:</p>
						<ol>
							<li><a href="#voraussetzungen">Nutzungsvoraussetzungen</a></li>
							<li><a href="#kosten">Fragen zu Kosten und Preisen</a></li>
							<li><a href="#anwendung">Fragen zur Anwendung für TP/AP, VT, ST</a></li>
							<li><a href="#allgemein">Allgemeine Fragen zur praktischen Anwendung</a></li>
							<li><a href="#datenschutz">Datenschutz</a></li>
							<li><a href="#bilder">Fragen zu den Bildern im Programm</a></li>
							<li><a href="#bericht">Fragen zur fertigen Berichts-Datei am Ende</a></li>
							<li><a href="#arztbrief">Anwendung Arztbriefe/Konsil</a></li>
							<li><a href="#upgrades">Fragen zu den Upgrades</a></li>
							<li><a href="#handy">Fragen zur Handy- oder Tablet-Anwendung</a></li>
							<li><a href="#inhalt">Inhaltliche Fragen zu den Leitfäden</a></li>
							<li><a href="#feedback">Fragen zum Feedback</a></li>
							<li><a href="#urheber">Frage zum Urheber und Konzept</a></li>
							<li><a href="#sonstiges">Sonstige Fragen</a></li>
							<li><a href="#fortbildung">Fragen zu möglichen Fortbildungspunkten</a></li>
							<li><a href="#technik">Fragen zu technischen Fehlern</a></li>
						</ol>
					</Card.Body>
				</Card>
				<div className="mb-5">
					<h2 id="voraussetzungen">Nutzungsvoraussetzungen</h2>
					<h5>Was sind die technischen Voraussetzungen, um das Programm zu nutzen?</h5>
					<p>Sie benötigen einen Internet-Browser: Google Chrome, Microsoft Edge, Mozilla Firefox, Safari und Opera in der jeweils aktuellsten Version. Sie benötigen ein Textverarbeitungsprogramm: Microsoft Word, Open Office, Libre Office, Pages von Apple. Sie benötigen eine Internetverbindung</p>
				</div>
				<div className="mb-5">
					<h2 id="kosten">Fragen zu Kosten und Preisen</h2>
					<h5>Was kostet das Programm?</h5>
					<p>Es stehen Ihnen zwei verschiedene kostenpflichtige Zugänge zur Verfügung: entweder 9,99&euro; für <a href="https://psy-dak.de/produkt/psychodynamik-navi-1-tages-lizenz" target="_blank" rel="noreferrer">1 Tag Zugang</a> oder 34,99&euro; für <a href="https://psy-dak.de/produkt/psychodynamik-navi-zugang-7-tages-lizenz" target="_blank" rel="noreferrer">7 Tage Zugang</a></p>
					<h5>Wo kann ich den Zugang käuflich erwerben?</h5>
					<p>Im Shop der Psy-Dak-Seite (Ingo Jungclaussen) können Sie den Zugang käuflich erwerben unter <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">www.psy-dak.de/shop</a></p>
					<h5>Wie genau geht das mit dem Kauf?</h5>
					<p>Sie kaufen zunächst eine Lizenz im <a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">Psy-Dak-Shop</a> per Paypal. Dann erhalten Sie automatisch per E-Mail einen Zugang (Lizenzschlüssel) zugesendet (ggf. Spamordner gucken). Dieser Lizenzschlüssel ist dann entsprechend lang gültig. Dann gehen Sie auf die Seite <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">www.psychodynamik-navi.de</a> und klicken im Menü auf <q>Bericht schreiben</q> und klicken auf den orangenen Knopf <q>Dann klicken Sie hier</q>. Danach geben Sie auf der nächsten Seite den per Mail zugesendeten Lizenz-Code mit Ihrer Tastatur ein. Achten Sie auf die Bindestriche beim Eingeben des Lizenz-Codes. Sie müssen also einmal zwischen 2 Seiten (<a href="https://www.psy-dak.de/shop" target="_blank" rel="noreferrer">Psy-Dak</a> und <a href="https://www.psychodynamik-navi.de" target="_blank" rel="noreferrer">Navi</a>) einmal hin- und her springen.</p>
					<h5>Wo finde ich die Allgemeinen Geschäftsbedingungen zum Psychodynamik-Navi?</h5>
					<p>Diese finden Sie hier: <a href="https://psychodynamik-navi.de/agb" target="_blank" rel="noreferrer">https://psychodynamik-navi.de/agb</a></p>
					<h5>Ist denn Psy-Dak (Shop) das gleiche wie Psychodynamik-Navi?</h5>
					<p>Ja: Hinter beiden Angeboten stehe ich (Ingo Jungclaussen). Psy-Dak ist gewissermaßen die <q>Mutterseite</q>.</p>
					<h5>Wie erfolgt denn die Bezahlung im Shop?</h5>
					<p>Diese erfolgt bis auf Weiteres nur über Paypal. Es ist also erforderlich, dass Sie ein Paypal-Konto haben. Sollten Sie hierüber nicht verfügen, ist ein Zugang derzeit schwierig. Nehmen Sie bei Rückfragen hierfür sonst gerne mit mir Kontakt auf. (<a href="mailto:info@psy-dak.de">info@psy-dak.de</a>)</p>
					<h5>Ich habe verstanden, dass es zeitlich begrenzte Lizenzen gibt, also 1 und 7 Tages-Lizenzen. Ab wann wird die Zeit eigentlich gezählt? Beim Kauf im Shop auf der Psy-Dak Seite oder erst beim Einloggen auf der Navi-Seite?</h5>
					<p>Die Zeit startet nach Ihrem Kauf im Shop, nicht erst beim Einloggen auf der Navi-Seite. Sie erhalten aber etwas zeitliche Kulanz, d. h. der Code endet immer erst um 23:59 Uhr am letzten Gültigkeitstag. D.h. wenn Sie z.B. an einem Freitag um 13 Uhr einen Code für 1 Tag kaufen, gilt dieser noch bis Samstag, 23:59 Uhr. Sie erhalten also noch etwas mehr Zeit geschenkt.</p>
					<h5>Kann ich mich in der Lizenz-Zeit eigentlich so oft ich will, wieder mit dem Code ein- und ausloggen im Navi?</h5>
					<p>Ja das geht selbstverständlich beliebig oft.</p>
					<h5>Ich bin Ausbildungskandidat/in: Bekomme ich dann einen Preisnachlass für Psychotherapeuten in Ausbildung (PiA)?</h5>
					<p>Das ist grundsätzlich ein sehr berichtigtes Anliegen. Ich bitte aber um Nachsicht, dass die Organisation, einen PiA-Nachweis zu überprüfen (Ausbildungsausweis zusenden, überprüfen, zurückmelden etc.) die Organisationskapazitäten meines Angebots übersteigt. Deswegen kann ich leider keinen eigenen PiA-Zugang anbieten. Sollten aber ganze Ausbildungs-Institute hieran Interesse haben, ist es denkbar gesonderte Nutzungsvereinbarungen für Institute zu vereinbaren. Hierfür kann sich die Institutsleitung sehr gerne an mich wenden (<a href="mailto:info@psy-dak.de">info@psy-dak.de</a>)</p>
				</div>
				<div className="mb-5">
					<h2 id="anwendung">Fragen zur Anwendung für TP/AP, VT, ST</h2>
					<h5>Ich habe verstanden, dass das Programm auf die TP spezialisiert ist. Kann ich das Programm aber auch für Berichte in der analytischen Psychotherapie (AP), Verhaltenstherapie (VT) oder Systemischen Psychotherapie (ST) nutzen?</h5>
					<p>Ja das geht. Wenn Sie im Programm im Schreibmodus an die Stelle von Punkt 4 (PTV3) kommen, erhalten Sie dort die Möglichkeit diesen Punkt 4 auch ohne TP-Leitfaden zu schreiben. Dann können Sie Punkt 4 also frei schreiben (in einem Textfeld, ohne Leitfaden-Schritte), so dass Sie so Punkt 4 für Ihr jeweiliges Verfahren individuell ausfüllen können (in einem Textfeld). So kann das Programm für alle Verfahren genutzt werden.</p>
					<h5>Ich habe verstanden, dass das Programm auf die TP spezialisiert ist. Wie ist das mit dem besagten TP-Leitfaden beim Punkt <q>Psychodynamik</q>? Kann ich den auch für die analytische Psychotherapie (AP) verwenden?</h5>
					<p>Nein. Die Indikation und die hierfür entsprechende Psychodynamik ist in der analytischen Psychotherapie (A) eine andere als in der tiefenpsychologisch fundierten Psychotherapie (TP). In der AP ist es vor allem erforderlich die repetitiven dysfunktionalen (Konflikt-) Muster des Patienten darzustellen, mit denen sich der Pat. lange selbst im Weg steht und jegliche Weiterentwicklung verhindert wird. Der TP-Leitfaden ist für eine andere spezifische TP-Indikation ausgelegt (aktualisierte und abgrenzbare Störungsdynamik). Wenn Sie sich für das Angebot eines spezifischen AP-Leitfadens bei Psychodynamik-Naviinteressieren, nehmen Sie gerne mit mir per Mail Kontakt auf (<a href="mailto:info@psy-dak.de">info@psy-dak.de</a>). Ansonsten verweise ich auf den AP-Leitfaden in meinem Handbuch in Kapitel C4.</p>
				</div>
				<div className="mb-5">
					<h2 id="allgemein">Allgemeine Fragen zur praktischen Anwendung</h2>
					<h5>Kann ich innerhalb der Schreib-Schritte eigentlich frei vor – und zurückgehen? Wird dann nicht etwas gelöscht oder so?</h5>
					<p>Nein, Sie können innerhalb der Schreibschritte so oft Sie wollen vor- und zurückgehen, es werden hierdurch Ihre Eintragungen nicht gelöscht. Das hat den Vorteil, dass Sie Ihren Bericht auch nach einer Pause weiterschreiben können, solange Ihr Browserfenster geöffnet ist.</p>
					<h5>Kann ich meinen Bericht irgendwie zwischenspeichern und dann danach wieder daran weiterarbeiten?</h5>
					<p>Ja das geht. Diese Funktion wurde für Sie eingeführt. Hierfür verwenden Sie die Export/Import-Funktion. Diese finden Sie direkt im Programm, wenn die Schreibfunktion gestartet wurde und zwar an der linken Seite. Sie tragen die Felder soweit aus, wie Sie wollen oder zeitlich können. Dann klicken Sie auf "Export". Hierdurch wird eine Export-Datei, mit dem Namen "Bericht.json" erstellt. Diese wird nur (!) auf Ihrem Rechner gespeichert. Wenn Sie an Ihrem Bericht im Programm irgendwann dann weiterschreiben wollen, klicken Sie im neu gestarteten Psychodynamik-Navi Programm auf "Import" und wählen diese Datei "Bericht.json" auf Ihrem Rechner im jeweiligen Ordner aus. Dann wird der bisherige Textstand ins Programm automatisch eingespielt und Sie können Ihren Bericht vervollständigen und dann abschließen.</p>
					<h5>Wie funktioniert die Import-/Export-Funktion?</h5>
					<p>Siehe Antworten oben.</p>
					<h5>Was bedeutet die Schaltfläche "Bericht vorzeitig abschließen"?</h5>
					<p>D. h., dass wenn Sie dort draufklicken, Ihr Bericht nur mit denjenigen Eintragungen erstellt wird, die Sie bis zu diesem Zeitpunkt getätigt haben (Das kann für Sie relevant sein, wenn Sie z.B. nur die Psychodynamik mit dem Navi schreiben wollen). Sie können aber Ihren Bericht auch mit der Export-/Import-Funktion zwischenspeichern und danach wieder vervollständigen. Siehe andere Frage.</p>
					<h5>Ich möchte meinen Punkt 4 (die Psychodynamik) auch ohne Schritt-für-Schritt-Leitfaden schreiben. Geht das auch?</h5>
					<p>Ja das geht. Kurz bevor es mit Punkt 4 im Programmablauf losgeht, können Sie auswählen, ob Sie den Punkt 4 (Psychodynamik) mit oder ohne Leitfaden schreiben wollen. Wenn Sie "ohne" anklicken, dann haben Sie ein einfaches Textfeld hierfür zur Verfügung. Danach könnten Sie z.B. auf "Bericht vorzeitig abschließen" klicken, dann wird Ihr Bericht mit diesem Stand erstellt. </p>
					<h5>Ich möchte das Programm nur(!) zum Verfassen der Psychodynamik nutzen. Geht das auch?</h5>
					<p>Ja das geht auch. Sie starten dafür das Programm regulär und springen dann auf der linken Seite (Sidebar) im Inhaltsverzeichnis, direkt auf Punkt 4 (Psychodynamik). Sie klicken  also "Psychodynamik" in der Sidebar mit der Maus an. Dann navigiert Sie das Programm direkt da hin. Dann füllen Sie diesen Punkt regulär aus. Dann können Sie auf "Bericht vorzeitig abschließen". Dann wird Ihr Bericht – nur mit diesen Punkt Psychodynamik- generiert.</p>
					<h5>Kann ich meinen Bericht auch vorzeitig, also bevor ich zum letzten Punkt angekommen bin, abschließen?</h5>
					<p>Ja hierfür klicken Sie im Programm auf "Bericht vorzeitig abschließen".</p>
					<h5>Gibt es im Programm eine Art Logout oder so?</h5>
					<p>Wenn Sie am Ende der Prozedur auf <q>Bericht generieren</q> oder während der Prozedur auf <q>Bericht vorzeitig abschließen</q> oder auf <q>Alle Daten löschen</q> klicken werden Ihre (lokal im Browser gespeicherten) Daten (also Ihre Texteintragungen) vollständig gelöscht. Das ist dann eine Art <q>Logout</q>.</p>
					<h5>Wann oder wie werden meine Eintragungen im Programm eigentlich gelöscht?</h5>
					<p>Wenn Sie am Ende der Prozedur auf <q>Bericht generieren</q> oder während der Prozedur auf <q>Bericht vorzeitig abschließen</q> oder auf <q>Alle Daten löschen</q> klicken werden Ihre (lokal im Browser gespeicherten) Daten (also Ihre Texteintragungen) vollständig gelöscht.</p>
					<h5>Was passiert, wenn ich das Programm im Schreibmodus geöffnet habe und dann meinen Browser oder mein Browserfenster (Tab) schließe, z.B. wenn ich den Computer runterfahre - Sind dann meine Texteintragungen gelöscht?</h5>
					<p>Jein: Je nachdem welche Browsereinstellungen Sie haben, kann es sein, dass Ihre Texteintragungen auch bei einem Neustart Ihres Browsers oder bei einem Neustart Ihres Computers noch lokal in Ihrem Browser gespeichert sind. Aber um Ihren Bericht nach einem Neustart weiterzuschreiben, empfiehlt sich viel mehr die Export-/Import-Funktion zu nutzen. S. anderen Frage.<br />
						Wenn es Ihnen darum geht, alle Daten nach Ihrer Nutzung zu löschen, dann klicken Sie im Programm im Schreibmodus auf "alle Daten löschen". Dann werden alle lokalen Texteintragungen in Ihrem Browser gelöscht. Das ist quasi dann eine Art Logout. Auch wenn Sie am Ende der Prozedur auf <q>Bericht generieren</q> oder während der Prozedur auf <q>Bericht vorzeitig abschließen</q> klicken werden Ihre (lokal im Browser gespeicherten) Daten (also Ihre Texteintragungen) vollständig gelöscht.
					</p>
					<h5>Wann werden meine Daten gelöscht bzw. wie kann ich diese löschen?</h5>
					<p>Klicken Sie hierfür im Schreibprogramm auf "alle Daten löschen". Im Übrigen werden Ihre Daten auch gelöscht, wenn am Ende der Bericht generiert wird oder Sie auf <q>Bericht vorzeitig abschließen</q> klicken. Dann werden alle Ihre Eintragungen gelöscht. Das entspricht dann einer Art "Logout". Diese sind dann nur noch in Ihrem Bericht als Word-Datei. Siehe auch Datenschutz. </p>
					<h5>Gibt es ein Logout?</h5>
					<p>Ja, siehe Antwort oben.</p>
				</div>
				<div className="mb-5">
					<h2 id="datenschutz">Datenschutz</h2>
					<h5>Wie wird der Datenschutz sichergestellt?</h5>
					<p>
						Alle einschlägigen Rechtsvorgaben (u.a. zum Medizinprodukterecht oder Datenschutz) werden eingehalten. Die Datenschutzerklärung finden Sie hier: <Link to="/privacy">https://psychodynamik-navi.de/privacy</Link><br/>
						Der Datenschutz wird im Besonderen dadurch sichergestellt, indem das Programm keine Daten/keine Texteintragungen außerhalb Ihres Computers speichert. Es werden keine Daten an einen Server gesendet oder dort gespeichert. Alle Ihre Texteintragungen verbleiben lokal in Ihrem Browser, diese verlassen also zu keinem Zeitpunkt Ihren Computer.<br/>
						Bei darüberhinaus weitergehenden rechtlichen Rückfragen zur Schreibhilfe stehen Ihnen gerne auch die Anwälte der Kanzlei <a href="https://www.skwschwarz.de/" target="_blank" rel="noreferrer">SKW Schwarz</a> zur Verfügung. Ihr Ansprechpartner ist: <a href="https://www.skwschwarz.de/personen/matthias-orthwein" target="_blank" rel="noreferrer">Dr. Matthias Orthwein</a>, Rechtsanwalt und Partner (<a href="mailto:m.orthwein@skwschwarz.de">m.orthwein@skwschwarz.de</a>).
					</p>
					<h5>Werden meine Daten irgendwie an einen Server geschickt oder dort gespeichert?</h5>
					<p>Nein: Ihre Daten werden nicht auf einem Server gespeichert (s. auch oben). Das wurde bei der Programmierung extra berücksichtigt und erfolgreich umgesetzt: Ihr Texteintragungen bleiben lokal im Browser Ihres Rechners. Ihre Daten/Texteintragungen verlassen also zu keinem Zeitpunkt Ihren Computer. Nach Beendigung Ihrer Nutzung klicken Sie auf "Alle Daten löschen", dann werden auch Ihre lokal in Ihrem Browser gespeicherten Daten gelöscht.<br />
						Weitere Infos <Link to="/privacy">hier</Link>
					</p>
					<h5>Benutzt das Programm oder die Seite irgendwelche Cookies, Google Analytics oder Ähnliches?</h5>
					<p>Nein, all das nutzt die Seite nicht. Die Seite ist bewusst aus datenschutzrechtlicher Sicht besonders "clean".</p>
				</div>
				<div className="mb-5">
					<h2 id="bilder">Fragen zu den Bildern im Programm</h2>
					<h5>Mich nerven etwas die Bilder. Kann ich diese abstellen?</h5>
					<p>Ja, wenn das Programm startet, haben Sie die Möglichkeit mit einem Klick (Häkchen setzen) zu entscheiden, ob die Bilder angezeigt werden sollen oder nicht.</p>
				</div>
				<div className="mb-5">
					<h2 id="bericht">Fragen zur fertigen Berichts-Datei am Ende</h2>
					<h5>Was mache ich, wenn mein Bericht durch das Programm plötzlich viel zu lang geworden ist?</h5>
					<p>Das kann durchaus passieren. Dann kürzen Sie Ihren am Ende generierten Bericht einfach in einem eigenen Arbeitsschritt (Word-Datei).</p>
					<h5>Unterscheidet sich die Word-Datei am Ende von einer normalen Word-Datei?</h5>
					<p>Nein.</p>
				</div>
				<div className="mb-5">
					<h2 id="arztbrief">Anwendung Arztbriefe/Konsil</h2>
					<h5>Ich bin Ärztin und möchte das Programm für einen ärztlichen Konsilbericht mit einer kurzen Psychodynamik nutzen, also nicht alle Punkte. Geht das auch?</h5>
					<p>Ja das geht auch. Dann empfehle ich Ihnen das Programm nur mit Punkt 4 (Psychodynamik) zu nutzen: Sie starten dafür das Programm regulär und springen dann auf der linken Seite (Sidebar) im Inhaltsverzeichnis, direkt auf Punkt 4 (Psychodynamik). Dann füllen Sie diesen aus und springen an den letzten Punkt. Danach gehen Sie weiter, bis Ihr Bericht generiert wird. </p>
				</div>
				<div className="mb-5">
					<h2 id="upgrades">Fragen zu den Upgrades</h2>
					<h5>Wissen Sie schon, wann die ganzen Upgrade-Funktionen angeboten werden?</h5>
					<p>Diese sind im Aufbau. Die Infoseite hierzu finden Sie hier: <Link to="/upgrades">https://psychodynamik-navi.de/upgrades</Link>. Wenn Sie zeitnah hierüber informiert werden wollen, empfehle ich Ihnen den Eintrag in meinen kostenlosen Newsletter unter <a target="_blank" rel="noreferrer" href="https://www.psy-dak.de/newsletter">www.psy-dak.de/newsletter</a>. Bei näherem Interesse schreiben Sie mir eine E-Mail unter <a href="mailto:info@psy-dak.de">info@psy-dak.de</a></p>
				</div>
				<div className="mb-5">
					<h2 id="handy">Fragen zur Handy- oder Tablet-Anwendung</h2>
					<h5>Kann ich auch den Bericht auf dem Handy oder Tablet schreiben?</h5>
					<p>Ja das geht genauso. Lediglich einige Optionen aus der linken Sidebar werden in der Handy/Tablet-Version ggf. nicht angezeigt. </p>
				</div>
				<div className="mb-5">
					<h2 id="inhalt">Inhaltliche Fragen zu den Leitfäden</h2>
					<h5>Was kann ich machen, wenn ich etwas mehr über die Psychodynamik-Leitfäden inhaltlich erfahren möchte?</h5>
					<p>Dann empfehle ich Ihnen die Lektüre meines <a target="_blank" rel="noreferrer" href="https://psy-dak.de/das-buch-zum-seminar/neuauflage-handbuch-2018/">Handbuches</a> in Buchteil C (dort werden diese ausführlich dargestellt) oder den Besuch meiner Fortbildungs-Seminare <a target="_blank" rel="noreferrer" href="https://www.psy-dak.de">www.psy-dak.de</a> (dort werden diese ausführlich vorgestellt, eingeübt und diskutiert)</p>
				</div>
				<div className="mb-5">
					<h2 id="feedback">Fragen zum Feedback</h2>
					<h5>Wo kann ich mein Feedback zu diesem Programm abgeben?</h5>
					<p>Das können Sie <Link to="/feedbackSurvey">hier</Link> abgeben</p>
				</div>
				<div className="mb-5">
					<h2 id="urheber">Frage zum Urheber und Konzept</h2>
					<h5>Wer ist der Erfinder oder Urheber dieser Anwendung?</h5>
					<p>Prof. Dr. Ingo Jungclaussen, der Gründer von Psy-Dak – Institut für Psychodynamische Didaktik (<a target="_blank" rel="noreferrer" href="https://www.psy-dak.de">www.psy-dak.de</a>). Beachten Sie hierzu auch die die Punkte <q>Inhalte (geistiges Eigentum)</q> in den <Link to="/agb">AGB</Link></p>
				</div>
				<div className="mb-5">
					<h2 id="fortbildung">Fragen zu möglichen Fortbildungspunkten</h2>
					<h5>Ich habe auf der Navi-Seite gelesen, dass es die Idee von Fortbildungspunkten gibt. Was genau ist damit gemeint?</h5>
					<p>
						Dies ist ein im Aufbau befindliches Konzept. Die grundsätzliche Idee ist, dass es zu einem späteren Zeitpunkt eine Version des Programms geben wird, in der Sie entlang der Gliederungspunkte zahlreiche fachbezogene Informationen lesen können. Die Gliederungspunkte des Antragsberichts berühren ja umfassende fachliche/theoretische Aspekte aus dem Bereich der Psychodynamischen Psychotherapie (Fachwissen). Das Ziel ist es, dass über den Eintragfeldern im Navi Schaltflächen zum Anklicken zu finden sind, durch die Sie dann diese fachbezogenen Informationen (Fachwissen) auffrischen und lesen können. Die Idee im Rahmen der Fortbildungsversion ist weiter, dass Sie im Nachgang Fragen zu diesen Inhalten (Fachwissen) beantworten können. Über die richtige Anzahl der richtigen Antworten können Fortbildungspunkte vergeben werden. Wenn Sie über Infos zum Start interessiert sind, empfehle ich Ihnen den Eintrag in meinen allg. Newsletter: <a href="https://psychodynamik-navi.de/newsletter" target="_blank" rel="noreferrer">https://psychodynamik-navi.de/newsletter</a><br/>
						Wenn Sie deutliches Interesse hieran haben, schreiben Sie mir gerne unter <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>
					</p>
				</div>
				<div className="mb-5">
					<h2 id="sonstiges">Sonstige Fragen</h2>
					<h5>Wo finde ich denn einen TP-Musterfall (Bericht an den Gutachter), um den Grad der Ausführlichkeit und um den fachlichen sprachlichen Ausdruck zu sehen?</h5>
					<p>Einen vollständigen Musterfall, wie ein fertiger Bericht an den Gutachter aussieht, finden Sie in meinem <a href="https://psy-dak.de/das-buch-zum-seminar/neuauflage-handbuch-2018/" target="_blank" rel="noreferrer">Handbuch Psychotherapieantrag</a> ab der Seite 481 (Kap. 20).</p>
					<h5>Wird es auch mal von Psychodynamik-Navi eine Desktop-Version (also ein Programm zum Downloaden) geben?</h5>
					<p>Nein, bis auf weiteres nicht. Die browserbasierte Variante hat für alle Beteiligten Vorteile: so können Sie von Neuerungen und Verbesserungen direkt profitieren.</p>
				</div>
				<div className="mb-5">
					<h2 id="technik">Fragen zu technischen Fehlern</h2>
					<p>Einige Anwender*innen hatten in einer älteren Version des Leitfadens, bei der es einen kl. Fehler gab, das Problem, dass die Navigation am Ende in einem Kreis verlief. Wenn Sie dieses Problem hatten, können Sie unter <a href="/app/finished">diesem Link</a> den Bericht noch nachträglich generieren und abschließen. In der neuen Version, in der das Problem behoben wurde und bei nachfolgenden Berichten tritt dieses Problem nicht mehr auf. (Ansonsten ignorieren Sie diesen Hinweis)</p>
					<h5>Was muss ich machen, wenn irgendetwas nicht funktioniert?</h5>
					<p>Machen Sie bitte vom Bildschirm (wo etwas nicht funktioniert) einen Screenshot/ein Foto und senden mir dies mit Angaben des Problems eine E-Mail an <a href="mailto:info@psy-dak.de">info@psy-dak.de</a>. Dann erhalten Sie eine entsprechende Abhilfe.</p>
				</div>
				<p>Senden Sie mir gerne Ihren Fragen zu unter <a href="mailto:info@psy-dak.de">info@psy-dak.de</a></p>
				<p>Dann werden diese hier beantwortet.</p>
				<p>Mit freundlichen Grüßen<br />Prof. Dr. Ingo Jungclaussen.</p>
			</div>
		</Container>
	);
}