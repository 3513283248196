import { Modal } from "react-bootstrap";
import { Images } from "../../img/Images";
import ChecklistItem from "../../models/ChecklistItem";
import ChecklistItemImage, { isChecklistItemImage } from "../../models/ChecklistItemImage";
import ChecklistItemTask, { isChecklistItemTask } from "../../models/ChecklistItemTask";
import ChecklistPage from "../../models/ChecklistPage";
import AppItemTask from "../appItemTask/AppItemTask";
import AppItemTabs from "./AppItemTabs";

import styles from "./AppItem.module.scss";
import ChecklistItemChoice, { isChecklistItemChoice } from "../../models/ChecklistItemChoice";
import AppItemChoice from "../appItemChoice/AppItemChoice";
import { useState } from "react";

export interface AppItemProps {
	page: ChecklistPage;
	item: ChecklistItem;
}

export default function AppItem(props: AppItemProps) {
	const [showImage, setShowImage] = useState(false);
	const handleClose = () => setShowImage(false);
	const handleShow = () => setShowImage(true);

	return (
		<>
			<div className="mx-3">
				{props.item.title &&
					<h4 className="mb-3">{props.item.title}</h4>
				}
				{isChecklistItemImage(props.item) ?
					<div className={`${styles.imageContainer} d-block m-auto text-center`}>
						<img src={Images[(props.item as ChecklistItemImage).image]} alt="Bild" className="img-fluid clickable" onClick={handleShow} />
						<span className="d-block fs-6">Zum Bild-größer-machen aufs Bild klicken</span>
					</div>
					:
					props.item.items.map(item => {
						let itemComponent = <></>;

						if (isChecklistItemTask(item)) {
							itemComponent = <AppItemTask page={props.page} item={props.item} taskItem={item as ChecklistItemTask} />;
						} else if (isChecklistItemChoice(item)) {
							itemComponent = <AppItemChoice page={props.page} item={props.item} choiceItem={item as ChecklistItemChoice} />;
						}

						return (
							<div key={item.key}>
								<AppItemTabs item={item} />
								<div className="my-3">
									{itemComponent}
								</div>
							</div>
						);
					})
				}
			</div>
			{isChecklistItemImage(props.item) &&
				<Modal show={showImage} onHide={handleClose} centered size="xl">
					<Modal.Header closeButton />
					<Modal.Body>
						<div className={`${styles.modalContainer}`}>
							<img src={Images[(props.item as ChecklistItemImage).image]} alt="Bild" />
						</div>
					</Modal.Body>
				</Modal>
			}
		</>
	);
}