import ChecklistItemContent from "./ChecklistItemContent";

export default interface ChecklistItemTask extends ChecklistItemContent {
	tasks: string[];
	textInput?: string;
}

export const isChecklistItemTask = (object: any): object is ChecklistItemTask => {
	return "tasks" in object;
};
