import ChecklistItemContent from "./ChecklistItemContent";

export interface ChecklistItemChoiceOption {
	key: string;
	option: string;
	text: string;
	page: string;
	patchNextPageKeys: string[],
	patchPrevPageKeys: string[]
}

export default interface ChecklistItemChoice extends ChecklistItemContent {
	options: ChecklistItemChoiceOption[];
}

export const isChecklistItemChoice = (object: any): object is ChecklistItemChoice => {
	return "options" in object;
};