import { Container } from "react-bootstrap";
import { Link } from "react-router-dom"

export default function Feedback() {
	return (
		<>
			<Container className="mb-5">
				<div>
					<h1 className="text-center mb-3">Feedback</h1>
					<h3>Feedback abgeben</h3>
					<p>
						Feedback können Sie <Link to="/feedbackSurvey">hier</Link> abgeben.
					</p>
					<h3 className="mb-3">Nutzer*innen-Stimmen:</h3>
					<figure>
						<blockquote className="blockquote">
							<p>
								Erst ein Mal <q>DANKE</q> für den Psychodynamik-Navi!<br/>
								Dass ich im Download anschließend alles bereits mit Überschriften versehen fertig habe: IST EIN TRAUM!<br/>
								Also wirklich nur Lob und sehr, sehr viel Arbeitserleichterung und Psychotherapeuten-Glück. Das Buch, das Seminar waren schon sehr hilfreich. Seit dem Psychodynamik-Navi, kann ich wirklich direkt anfangen ohne vorher ständig <q>noch irgend etwas erledigen zu müssen</q>.
							</p>
						</blockquote>
						<figcaption className="blockquote-footer">
							Psychotherapeutin Anke-Maria Hausmann <cite title="Quelle">per Mail am 23.01.2022</cite>
						</figcaption>
					</figure>
				</div>
			</Container>
		</>
	);
}
