import React from "react";
import { Container } from "react-bootstrap";

interface ILayoutProps {
	children: React.ReactNode;
}

export default function Layout({ children }: ILayoutProps) {
	return (
		<Container fluid className="mt-1 d-flex flex-grow-1 flex-column">
			<Container as="main" fluid className="mt-2 d-flex flex-grow-1 flex-column mb-5">
				{children}
			</Container>
		</Container>
	);
}